import { IStatusTypesData } from "../interfaces/IStatusTypesData";
import { IClaimStatesData } from "../interfaces/IClaimStatesData";
import { Address } from "src/classes/Address";
import { IClaimStateByPostalCodeData } from "../interfaces/IClaimStateByPostalCodeData";
import { StatusTypeItem } from "src/classes/claims/StatusTypeItem";
import { IClaimFormRequest } from "../interfaces/IClaimFormRequest";
import { ClaimRegister } from "src/classes/claims/ClaimRegister";
import { IClaimPersonData } from "../interfaces/IClaimPersonData";
import { Person } from "src/classes/Person";
import { Profile } from "src/classes/Profile";
import { License } from "src/classes/claims/License";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { VehicleBrand } from "src/classes/vehicle/VehicleBrand";
import { VehicleModel } from "src/classes/vehicle/VehicleModel";
import { VehicleYear } from "src/classes/vehicle/VehicleYear";
import { Damage } from "src/classes/claims/Damage";
import { Phone } from "src/classes/Phone";
import { IClaimPersonInfoUpdateRequest } from "../interfaces/IClaimPersonInfoUpdateRequest";
import { Util } from "src/utils/Util";
import { IClaimPictureData } from "../interfaces/IClaimPictureData";
import { ClaimRegisterPicture } from "src/classes/claims/ClaimRegisterPicture";
import { IClaimPicture } from "../interfaces/IClaimPicture";
import { IClaimAdjusterData } from "../interfaces/IClaimAdjusterData";
import { ClaimAdjuster } from "src/classes/claims/ClaimAdjuster";
import { IClaimPeople } from "../interfaces/IClaimPeople";
import { IClaimAdjusterInfoUpdateRequest } from "../interfaces/IClaimAdjusterInfoUpdateRequest";
import { IClaimAdjusterPeople } from "../interfaces/IClaimAdjusterPeople";
import { Coverage } from "src/classes/plan/Coverage";
import { IClaimProvidersRequest } from "../interfaces/IClaimProvidersRequest";
import { IClaimServiceTypesRequest } from "../interfaces/IClaimServiceTypesRequest";
import { Provider } from "src/classes/claims/Provider";
import { Service } from "src/classes/claims/Service";
import { ICoveragesListRequest } from "../interfaces/ICoveragesListRequest";
import { IProvidersListData } from "../interfaces/IProvidersListData";
import { Policy } from "src/classes/Policy";
import { IClaimEduaInfoRequest } from "../interfaces/IClaimEduaInfoRequest";
import { ILiabilityListRequestParams } from "../interfaces/ILiabilityListRequestParams";
import { IPersonTypeListRequestParams } from "../interfaces/IPersonTypeListRequestParams";

export namespace ClaimRegisterAdapter {

  /**
   * 
   * @param currentClaimRegister 
   * @param personData 
   * @returns 
   */
  const setClaimRegisterData = (currentClaimRegister: ClaimRegister, personData: IClaimPeople): ClaimRegister => {
    let person: Person = new Person();
    let damages: Damage[] = [];
    person.id = personData.id;
    person.email = personData.email!;
    person.name = personData.first_name!;
    person.lastName = personData.first_last_name!;
    person.secondLastName = personData.second_last_name!;
    currentClaimRegister.isPrior = personData.previous;

    if (personData.folId)
      currentClaimRegister.folId = personData.folId;

    let personProfile: Profile = new Profile();
    personProfile.birthdate = personData.birthdate ? new Date(`${personData.birthdate}T23:59`) : null;

    if (personData.phone) {
      personProfile.phone = new Phone();
      personProfile.phone.setCountryCodeByFullNumber(personData.phone);
    }

    if (personData.gender) {
      if (personData.gender === 'Femenino') {
        personProfile.gender = Util.STATUS_CODE.GENDER.FEMALE;
      } else if (personData.gender === 'Masculino') {
        personProfile.gender = Util.STATUS_CODE.GENDER.MALE;
      }
    }

    if (personData.address) {
      let address: Address = new Address(personData.address.postal_code);
      address.state = personData.address.state!;
      address.municipality = personData.address.municipality!;
      address.name = personData.address.name!;
      if (personData.address.latitude && personData.address.longitude) {
        address.latitude = parseFloat(personData.address.latitude);
        address.longitude = parseFloat(personData.address.longitude);
      }
      personProfile.address = address;
    }

    if (personData.license && personData.license.number) {
      let licence = new License(personData.license.number);
      if (personData.license.startDate) {
        licence.startDate = Util.TRANSFORM.DATE.serverStringDateToClientDate(personData.license.startDate);
      }

      if (personData.license.endDate) {
        licence.endDate = Util.TRANSFORM.DATE.serverStringDateToClientDate(personData.license.endDate);
      }

      licence.stateName = personData.license.state!;
      if (personData.license.typeStatus && personData.license.typeStatus.id && personData.license.typeStatus.name) {
        licence.type = new StatusTypeItem(personData.license.typeStatus.id, personData.license.typeStatus.name);
      }

      currentClaimRegister.license = licence;
    }

    let vehicle: Vehicle = new Vehicle();
    vehicle.brand = new VehicleBrand();
    vehicle.model = new VehicleModel();
    vehicle.year = new VehicleYear();

    const vehicleData = personData.vehicle;
    if (vehicleData) {

      if (vehicleData.brand) {
        vehicle.brand.name = vehicleData.brand;
      }

      if (vehicleData.model) {
        vehicle.model.name = vehicleData.model;
      }

      if (vehicleData.year) {
        vehicle.year.value = vehicleData.year;
      }

      vehicle.vin = vehicleData.seriesNumber!;
      vehicle.plate = vehicleData.plates!;
      vehicle.id = vehicleData.id;

			if (vehicleData.comercialValue !== undefined)
				vehicle.comercialValue = vehicleData.comercialValue;

      if (vehicleData.typeStatus && vehicleData.typeStatus.id && vehicleData.typeStatus.name) {
        currentClaimRegister.vehicleType = new StatusTypeItem(vehicleData.typeStatus.id, vehicleData.typeStatus.name);
      }

      if (vehicleData.colorStatus && vehicleData.colorStatus.id && vehicleData.colorStatus.name) {
        currentClaimRegister.vehicleColor = new StatusTypeItem(vehicleData.colorStatus.id, vehicleData.colorStatus.name);
      }

      const damagesData = vehicleData.damages;
      for (const damageData of damagesData) {
        let dmg: Damage = new Damage(new StatusTypeItem(damageData.typeStatus.id, damageData.typeStatus.name));
        dmg.id = damageData.id;
        dmg.damagePreexistence = damageData.preexistence;
        dmg.isPrior = damageData.previous;
        dmg.description = damageData.description!;
        for (const dmgPictureData of damageData.photos) {
          if (dmgPictureData) {
            let newDmgPicture = new ClaimRegisterPicture(dmgPictureData.id, dmgPictureData.link, dmgPictureData.name);
            newDmgPicture.type = new StatusTypeItem(dmgPictureData.typeStatus.id, dmgPictureData.typeStatus.name);
            dmg.pictures.push(newDmgPicture);
          }
        }

        damages.push(dmg);
      }
    } else {
      vehicle.brand.name = '';
      vehicle.model.name = '';
    }

    person.profile = personProfile;

    currentClaimRegister.person = person;
    currentClaimRegister.vehicle = vehicle;
    currentClaimRegister.damages = damages;

    if (personData.circumstance) {
      currentClaimRegister.circumstance = new StatusTypeItem(personData.circumstance.id!, personData.circumstance.name!);
    }

    if (personData.photos) {
      let licensePictures: ClaimRegisterPicture[] = new Array(2);
      let inePictures: ClaimRegisterPicture[] = new Array(2);
      let drivingPictures: ClaimRegisterPicture[] = new Array(1);
      let vinPictures: ClaimRegisterPicture[] = new Array(1);
      let vehiclePictures: ClaimRegisterPicture[] = new Array(4);
      for (const personPictures of personData.photos) {
        if (personPictures.typeStatus && personPictures.typeStatus.id) {
          let picture: ClaimRegisterPicture = new ClaimRegisterPicture(personPictures.id, personPictures.link, personPictures.name);
          picture.type = new StatusTypeItem(personPictures.typeStatus.id, personPictures.typeStatus.name);
          if (picture.type.isPersonFrontLicensePicture()) {
            licensePictures[0] = picture;
          } else if (picture.type.isPersonBackLicensePicture()) {
            licensePictures[1] = picture;
          } else if (picture.type.isPersonFrontInePicture()) {
            inePictures[0] = picture;
          } else if (picture.type.isPersonBackInePicture()) {
            inePictures[1] = picture;
          } else if (picture.type.isPersonDrivingPermission()) {
            drivingPictures[0] = picture;
          } else if (picture.type.isPersonVehicleVin()) {
            vinPictures[0] = picture;
          } else if (picture.type.isPersonVehicle()) {
            if (picture.name.includes('1')) {
              vehiclePictures[0] = picture;
            } else if (picture.name.includes('2')) {
              vehiclePictures[1] = picture;
            } else if (picture.name.includes('3')) {
              vehiclePictures[2] = picture;
            } else if (picture.name.includes('4')) {
              vehiclePictures[3] = picture;
            }
          }
        }
      }

      if (Array.from(licensePictures.values()).every(p => p !== undefined)) {
        currentClaimRegister.licensePictures = licensePictures;
      }

      if (Array.from(inePictures.values()).every(p => p !== undefined)) {
        currentClaimRegister.inePictures = inePictures;
      }

      if (Array.from(drivingPictures.values()).every(p => p !== undefined)) {
        currentClaimRegister.drivingPermissionPictures = drivingPictures;
      }

      if (Array.from(vinPictures.values()).every(p => p !== undefined)) {
        currentClaimRegister.vinPictures = vinPictures;
      }

      if (Array.from(vehiclePictures.values()).every(p => p !== undefined)) {
        currentClaimRegister.vehiclePictures = vehiclePictures;
      }
    }

    const isAnyPolicyValue = personData.policy && (personData.policy.insuranceProvider || personData.policy.number || personData.policy.claimNumber 
      || personData.policy.subsection || personData.policy.duaNumber || personData.policy.adjusterName);

    if (isAnyPolicyValue) {
      currentClaimRegister.policyNumber = personData.policy.number;
      currentClaimRegister.issueNumber = personData.policy.subsection;
      currentClaimRegister.thirdInsuranceClaimNumber = personData.policy.claimNumber;
			currentClaimRegister.thirdInsuranceDuaNumber = personData.policy.duaNumber;
			currentClaimRegister.thirdInsuranceAdjusterName = personData.policy.adjusterName;

      currentClaimRegister.insuranceCompany = new Provider();
      currentClaimRegister.insuranceCompany.id = personData.policy.insuranceProvider ? personData.policy.insuranceProvider.id : '';
      currentClaimRegister.insuranceCompany.name = personData.policy.insuranceProvider ? personData.policy.insuranceProvider.name : '';
    }

    if (personData.services) {
      for (const serviceData of personData.services) {
        let newService: Service = new Service();
        newService.amount = serviceData.amount!;
        newService.hasDeductible = serviceData.deductible!;
        newService.description = serviceData.description;
        newService.itemNumber = serviceData.sheetNumber!;
        newService.category = new StatusTypeItem(serviceData.categoryStatus?.id!, serviceData.categoryStatus?.name!);
        newService.type = new StatusTypeItem(serviceData.typeStatus?.id!, serviceData.typeStatus?.name!);
        newService.coverage = new StatusTypeItem(serviceData.coverageStatus?.id!, serviceData.coverageStatus?.name!);
        newService.operatorName = serviceData.operatorName!;
        newService.isPrior = serviceData.previous;

        if (serviceData.id) {
          newService.id = serviceData.id;
        }
				
        if (serviceData.operatorPhone) {
          newService.operatorPhone = new Phone();
          newService.operatorPhone.setCountryCodeByFullNumber(serviceData.operatorPhone);
        }

        if (serviceData.subprovider) {
          newService.provider = new Provider();
          newService.provider.id = serviceData.subprovider.id;
          newService.provider.name = serviceData.subprovider.name;
        } else if (serviceData.provider) {
          newService.provider = new Provider();
          newService.provider.id = serviceData.provider.id;
          newService.provider.name = serviceData.provider.name;
        }

        if (serviceData.photos) {
          for (const servicePictureData of serviceData.photos) {
            if (servicePictureData) {
              let newServicePicture = new ClaimRegisterPicture(servicePictureData.id, servicePictureData.link, servicePictureData.name);
              newServicePicture.type = new StatusTypeItem(servicePictureData.typeStatus.id, servicePictureData.typeStatus.name);
              newService.pictures.push(newServicePicture);
            }
          }
        }

        currentClaimRegister.services.push(newService);
      }
    }

    return currentClaimRegister;
  }

  /**
   * 
   * @param picture 
   * @returns 
   */
  const setClaimPicture = (picture: ClaimRegisterPicture): IClaimPicture => {
    return {
      name: picture.name,
      typeStatus: {
        id: picture.type?.id!,
        name: picture.type?.name!,
      },
      id: picture.id,
      link: picture.url,
      contentType: 'image/png',
    }
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const statusTypeDataAdapter = (data: IStatusTypesData): StatusTypeItem[] => {
    let types: StatusTypeItem[] = [];
    for (const status of data.response) {
      let newType: StatusTypeItem = new StatusTypeItem(status.id, status.name);
      types.push(newType);
    }

    types.sort((a, b) => {
      const aStartsWithAsterisk = a.name.startsWith('*');
      const bStartsWithAsterisk = b.name.startsWith('*');

      if (aStartsWithAsterisk && !bStartsWithAsterisk)
        return -1;
      else if (!aStartsWithAsterisk && bStartsWithAsterisk)
        return 1;
      else
        return a.name.localeCompare(b.name);
    })

    return types;
  }

  /**
   * 
   * @param data 
   */
  export const providersListDataAdapter = (data: IProvidersListData): Provider[] => {
    let _providers: Provider[] = []

    data.response.forEach((_item) => {
      let _provider: Provider = new Provider();
      _provider.id = _item.id;
      _provider.name = _item.name;

      if (_item.address) {
        _provider.address = new Address();
        _provider.address.state = _item.address.state;
        _provider.address.latitude = parseFloat(_item.address.latitude);
        _provider.address.longitude = parseFloat(_item.address.longitude);
        _provider.address.municipality = _item.address.municipality;
        _provider.address.postalCode = _item.address.postal_code;
        _provider.address.street = _item.address.name;
      }

      _providers.push(_provider);
    })

    return _providers;
  }

  /**
   * 
   * @param claimId 
   * @returns 
   */
  export const liabilityRequestParamsAdapter = (claimId: string): ILiabilityListRequestParams => {
    const params: ILiabilityListRequestParams = {
      claimId: claimId,
      isLiability: true,
    }

    return params;
  }

  /**
   * 
   * @param claimId 
   * @returns 
   */
  export const personTypeRequestParamsAdapter = (claimId: string, liabilityId?: string): IPersonTypeListRequestParams => {
    const params: IPersonTypeListRequestParams = {
      claimId: claimId,
      isPeopleType: true,
      ...(liabilityId ? { liabilityTypeId: liabilityId } : {})
    }

    return params;
  }

  /**
   * 
   * @param claimId 
   * @param personTypeId 
   * @param liabilityId 
   * @returns 
   */
  export const serviceTypesRequestAdapter = (claimId: string, personTypeId: string, liabilityId?: string): IClaimServiceTypesRequest => {
    let _params: IClaimServiceTypesRequest = {
      isServiceType: true,
      claimId: claimId,
      peopleTypeId: personTypeId,
      isAdjuster: 1,
      ...(liabilityId ? { liabilityTypeId: liabilityId } : {})
    };

    return _params;
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const statesDataAdapter = (data: IClaimStatesData): Address[] => {
    let list: Address[] = [];
    for (const state of data.response) {
      let address: Address = new Address();
      address.state = state.name;
      list.push(address);
    }

    return list;
  }

  /**
   * 
   * @param claimId 
   * @param serviceTypeId 
   * @param personTypeId 
   * @param liabilityId 
   * @returns 
   */
  export const coveragesListRequestAdapter = (claimId: string, serviceTypeId: string, personTypeId: string, liabilityId?: string): ICoveragesListRequest => {
    const requestParams: ICoveragesListRequest = {
      isCoverageType: true,
      claimId: claimId,
      peopleTypeId: personTypeId,
      serviceTypeId: serviceTypeId,
      liabilityTypeId: liabilityId
    }

    return requestParams;
  }

  /**
   * 
   * @param providerType 
   * @param serviceType 
   * @param isSubProvider 
   * @param category 
   * @returns 
   */
  export const providersListRequestAdapter = (
		providerType?: string, 
		serviceType?: string, 
		isSubProvider?: boolean, 
		category?: string, 
		claimId?: string, 
		state?: string,
		repairProviderType?: number
	): IClaimProvidersRequest => {
    const requestParams: IClaimProvidersRequest = {
      ...(providerType ? { type: providerType } : {}),
      ...(serviceType ? { serviceType: serviceType } : {}),
      ...(isSubProvider !== undefined ? { isSubProvider: isSubProvider } : {}),
      ...(category ? { category: category } : {}),
      ...(claimId ? { claimId: claimId } : {}),
			...(state !== undefined ? { addressState: state } : {}),
			...(repairProviderType !== undefined && repairProviderType === Util.STATUS_CODE.CLAIM_PROVIDERS_TYPES.MULTIBRAND ? { multibrand: true } : {}),
			...(repairProviderType !== undefined && repairProviderType === Util.STATUS_CODE.CLAIM_PROVIDERS_TYPES.AGENCY ? { agency: true } : {})
    }

    return requestParams;
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const statesByPostalCodeDataAdapter = (data: IClaimStateByPostalCodeData): Address => {
    let address: Address = new Address();
    if (data.response.municipality && data.response.state) {
      address.municipality = data.response.municipality;
      address.state = data.response.state;
    }

    return address;
  }

  /**
   * 
   * @param id 
   * @param key 
   */
  export const claimFormRequestAdapter = (id: string, key: string): IClaimFormRequest => {
    let request: IClaimFormRequest = {
      id: id,
      key: key
    }

    return request;
  }

  /**
   * 
   * @param key 
   * @param claimAdjuster 
   * @param completed 
   * @param newPictures 
   * @param type 
   * @param personIndex 
   * @returns 
   */
  export const claimAdjusterInfoUpdateRequestAdapter = (key: string, claimAdjuster: ClaimAdjuster, completed?: boolean, newPictures?: ClaimRegisterPicture[], type?: TClaimPictureName, personIndex?: number): IClaimAdjusterInfoUpdateRequest => {

    let request: IClaimAdjusterInfoUpdateRequest = {
      id: claimAdjuster.formId,
      key: key,
      json: {
        peopleSectionConfirmed: claimAdjuster.isInvolvedsCompleted,
        adjuster: {
          name: claimAdjuster.name,
          email: claimAdjuster.email
        },
        typeStatus: {
          id: claimAdjuster.claimType.id,
          name: claimAdjuster.claimType.name,
        },
        id: claimAdjuster.claimId,
        number: claimAdjuster.claimNumber,
        completed: completed as boolean,
        started: true,
        occurred_at: Util.TRANSFORM.DATE.formatServerDateTime(claimAdjuster.date!),
        declaration: claimAdjuster.peopleFacts ? claimAdjuster.peopleFacts.trim() : undefined,
        declaration_cabin: claimAdjuster.cabinFacts ? claimAdjuster.cabinFacts.trim() : '',
        reporting_name: claimAdjuster.reportingName,
        reporting_phone: claimAdjuster.reportingPhone?.getFullNumber()!,
        third_car_description: claimAdjuster.vehicleInvolved,
        firstForm: claimAdjuster.isFirstForm,
        conclusion: claimAdjuster.clousure ? claimAdjuster.clousure.trim() : undefined,
        peoples: [],
        address: {
          municipality: claimAdjuster.location?.municipality.trim() || null!,
          street: claimAdjuster.location?.street.trim() || null!,
          state: claimAdjuster.location?.state.trim() || null!,
          postal_code: claimAdjuster.location?.postalCode || null!,
          latitude: claimAdjuster.location?.latitude?.toString() || null!,
          longitude: claimAdjuster.location?.longitude?.toString() || null!,
          reference: claimAdjuster.location?.genericReference.trim()
        },

      }
    }

    //#region Set Policy
    if (claimAdjuster.policy) {
      //#region Set coverages
      let coverages = [];
      for (const coverage of claimAdjuster.policy.coverages) {
        let item: { typeId: string, enabled: boolean, deductible?: number, name: string } = { typeId: coverage.id, enabled: coverage.isEnabled, name: coverage.type };
        if (coverage.deductible > 0) {
          item.deductible = coverage.deductible;
        }
        coverages.push(item);
      }
      //#endregion

      //#region Set status
      let statusName: string = '';
      if (claimAdjuster.policy.isActive()) {
        statusName = 'ACTIVE';
      } else if (claimAdjuster.policy.isCanceled()) {
        statusName = 'CANCELLED';
      } else if (claimAdjuster.policy.isPending()) {
        statusName = 'PENDING';
      } else if (claimAdjuster.policy.isExpired()) {
        statusName = 'EXPIRED';
      } else if (claimAdjuster.policy.toBegin()) {
        statusName = 'ISSUED';
      }
      //#endregion

      let policyInfo = {
        number: claimAdjuster.policy.policyNumber,
        startDate: Util.TRANSFORM.DATE.formatServerDateTime(claimAdjuster.policy.startDate!),
        endDate: Util.TRANSFORM.DATE.formatServerDateTime(claimAdjuster.policy.expirationDate!),
        id: claimAdjuster.policy.id,
        coverages: coverages,
        policyHolder: {
          firstName: claimAdjuster.policy.owner?.name!,
          firstLastName: claimAdjuster.policy.owner?.lastName!,
          secondLastName: claimAdjuster.policy.owner?.secondLastName!,
        },
        status: statusName
      };

      request.json.policyInfo = policyInfo;
    }

    if (claimAdjuster.declarationSignature) {
      request.json.na_declaration_signature = claimAdjuster.declarationSignature;
    }

    if (claimAdjuster.signature) {
      request.json.adjuster.signature = claimAdjuster.signature;
    }

    if (claimAdjuster.cause) {
      request.json.causeTypeStatus = {
        id: claimAdjuster.cause.id,
        name: claimAdjuster.cause.name
      }
    }

    if (claimAdjuster.liability) {
      request.json.liabilityStatus = {
        id: claimAdjuster.liability.id,
        name: claimAdjuster.liability.name
      }
    }

    if (claimAdjuster.reason) {
      request.json.conclusion = claimAdjuster.clousure.trim();
      request.json.cancelledReason = {
        id: claimAdjuster.reason.id,
        name: claimAdjuster.reason.name
      }
    }

    const mergedPictures = [...claimAdjuster.referencePictures, ...claimAdjuster.duaPicture];
    let jsonPhotos: IClaimPicture[] = [];
    for (const currentJsonPicture of mergedPictures) {
      jsonPhotos.push(setClaimPicture(currentJsonPicture));
    }

    if (type && newPictures && (type === 'references' || type === 'dua')) {
      const dataPictures: IClaimPicture[] = [];
      for (const picture of newPictures) {
        dataPictures.push(setClaimPicture(picture))
      }
      jsonPhotos = jsonPhotos.concat(dataPictures);
    }

    claimAdjuster.claimPeople.forEach(
      (claimPerson, index) => {
        let personRequest: IClaimAdjusterPeople =
        {
          claim_id: claimPerson.claimId,
          first_name: claimPerson.person?.name ? claimPerson.person?.name.trim() : null!,
          first_last_name: claimPerson.person?.lastName ? claimPerson.person?.lastName.trim() : null!,
          second_last_name: claimPerson.person?.secondLastName ? claimPerson.person?.secondLastName.trim() : null!,
          email: claimPerson.person?.email ? claimPerson.person?.email.trim() : null!,
          phone: claimPerson.person?.profile?.phone?.getFullNumber(true),
          birthdate: claimPerson.person?.profile?.birthdate ? Util.TRANSFORM.DATE.formatServerDate(claimPerson.person.profile.birthdate) : null!,
          typeStatus: {
            id: claimPerson.personType.id,
            name: claimPerson.personType.name,
          },
          signature: claimPerson.signature,
          previous: claimPerson.isPrior,
        }

        if (claimPerson.circumstance) {
          personRequest.circumstance = {
            id: claimPerson.circumstance.id,
            name: claimPerson.circumstance.name
          }
        }

        if (claimPerson.license) {
          personRequest.license = {
            number: claimPerson.license?.number ? claimPerson.license?.number.trim() : null!,
            state: claimPerson.license?.stateName || null!,
            endDate: claimPerson.license?.endDate ? Util.TRANSFORM.DATE.formatServerDate(claimPerson.license.endDate) : null!,
            startDate: claimPerson.license?.startDate ? Util.TRANSFORM.DATE.formatServerDate(claimPerson.license.startDate) : null!,
            typeStatus: {
              id: claimPerson.license?.type?.id || null!,
              name: claimPerson.license?.type?.name || null!
            }
          }
        }

        if (claimPerson.folId)
          personRequest.folId = claimPerson.folId;
        
        if (claimPerson.person.profile?.gender) {
          if (claimPerson.person.profile.gender === Util.STATUS_CODE.GENDER.FEMALE) {
            personRequest.gender = 'Femenino';
          } else if (claimPerson.person.profile.gender === Util.STATUS_CODE.GENDER.MALE) {
            personRequest.gender = 'Masculino';
          }
        }

        if (claimPerson.person?.id) {
          personRequest.id = claimPerson.person.id;
        }

        if (claimPerson.person?.profile?.address?.postalCode) {
          personRequest.address = {
            municipality: claimPerson.person?.profile?.address?.municipality || null!,
            name: claimPerson.person?.profile?.address?.name || null!,
            state: claimPerson.person?.profile?.address?.state || null!,
            postal_code: claimPerson.person?.profile?.address?.postalCode || null!,
          }
        }

        if (claimPerson.vehicleType) {
          personRequest.vehicle = {
            id: claimPerson.vehicle?.id,
            colorStatus: 
              claimPerson.vehicleColor?.id ? 
                {
                  id: claimPerson.vehicleColor?.id,
                  name: claimPerson.vehicleColor?.name
                } 
              : 
                undefined,
            // colorStatus: {
            //   id: claimPerson.vehicleColor?.id || null!,
            //   name: claimPerson.vehicleColor?.name || null!
            // },
            typeStatus: {
              id: claimPerson.vehicleType?.id || null!,
              name: claimPerson.vehicleType?.name || null!
            },
            brand: claimPerson.vehicle?.brand?.name ? claimPerson.vehicle?.brand?.name.trim() : undefined,
            model: claimPerson.vehicle?.model?.name ? claimPerson.vehicle?.model?.name.trim() : undefined,
            year: claimPerson.vehicle?.year?.value,
            plates: claimPerson.vehicle?.plate ? claimPerson.vehicle?.plate.trim() : undefined,
            seriesNumber: claimPerson.vehicle?.vin ? claimPerson.vehicle?.vin.trim() : undefined,
            comercialValue: index === 0 ? claimPerson.vehicle?.comercialValue : undefined,
          }

          let damagesRequest = [];
          for (const damage of claimPerson.damages) {
            let vehicleDamagesPhotos: IClaimPicture[] = [];

            for (const dmgPicture of damage.pictures) {
              vehicleDamagesPhotos.push(setClaimPicture(dmgPicture));
            }

            damagesRequest.push(
              {
                id: damage.id,
                typeStatus: {
                  id: damage.type.id,
                  name: damage.type.name
                },
                preexistence: damage.damagePreexistence,
                previous: damage.isPrior,
                photos: vehicleDamagesPhotos,
                description: damage.description ? damage.description.trim() : ''
              }
            );
          }
          personRequest.vehicle.damages = damagesRequest;
        }

        //#region Set Pictures
        let mergedPeoplePictures: ClaimRegisterPicture[] = [...claimPerson.licensePictures, ...claimPerson.inePictures, ...claimPerson.drivingPermissionPictures, ...claimPerson.vinPictures, ...claimPerson.vehiclePictures];
        let peoplePhotos: IClaimPicture[] = [];

        for (const currentPeoplePictures of mergedPeoplePictures) {
          peoplePhotos.push(setClaimPicture(currentPeoplePictures));
        }

        if (newPictures && type && personIndex !== undefined && personIndex === index) {
          switch (type) {
            case 'license': {
              let picturesToRequest: IClaimPicture[] = new Array(2);
              for (const picture of newPictures) {
                if (picture.type?.isPersonFrontLicensePicture()) {
                  picturesToRequest[0] = setClaimPicture(picture);
                } else if (picture.type?.isPersonBackLicensePicture()) {
                  picturesToRequest[1] = setClaimPicture(picture);
                } else if (picture.type?.isPersonFrontInePicture()) {
                  picturesToRequest[0] = setClaimPicture(picture);
                } else if (picture.type?.isPersonBackInePicture()) {
                  picturesToRequest[1] = setClaimPicture(picture);
                }
              }

              const missingPictureIndex = picturesToRequest.findIndex(p => p === undefined);
              if (missingPictureIndex > -1) {
                picturesToRequest[missingPictureIndex] = setClaimPicture(claimPerson.licensePictures[missingPictureIndex]);
              }

              peoplePhotos = peoplePhotos.concat(picturesToRequest);
              break;
            }

            case 'ine': {
              let picturesToRequest: IClaimPicture[] = new Array(2);
              for (const picture of newPictures) {
                if (picture.type?.isPersonFrontLicensePicture()) {
                  picturesToRequest[0] = setClaimPicture(picture);
                } else if (picture.type?.isPersonBackLicensePicture()) {
                  picturesToRequest[1] = setClaimPicture(picture);
                } else if (picture.type?.isPersonFrontInePicture()) {
                  picturesToRequest[0] = setClaimPicture(picture);
                } else if (picture.type?.isPersonBackInePicture()) {
                  picturesToRequest[1] = setClaimPicture(picture);
                }
              }

              const missingPictureIndex = picturesToRequest.findIndex(p => p === undefined);
              if (missingPictureIndex > -1) {
                picturesToRequest[missingPictureIndex] = setClaimPicture(claimPerson.inePictures[missingPictureIndex]);
              }

              peoplePhotos = peoplePhotos.concat(picturesToRequest);
              break;
            }

            case 'drivingPermission': {
              let picturesToRequest: IClaimPicture[] = [];
              picturesToRequest.push(setClaimPicture(newPictures[0]));
              peoplePhotos = peoplePhotos.concat(picturesToRequest);
              break;
            }

            case 'vin': {
              let picturesToRequest: IClaimPicture[] = [];
              picturesToRequest.push(setClaimPicture(newPictures[0]));
              peoplePhotos = peoplePhotos.concat(picturesToRequest);
              break;
            }

            case 'vehicle': {
              let picturesToRequest: IClaimPicture[] = new Array(4);
              for (const picture of newPictures) {
                if (picture.name.includes('1')) {
                  picturesToRequest[0] = setClaimPicture(picture);
                } else if (picture.name.includes('2')) {
                  picturesToRequest[1] = setClaimPicture(picture);
                } else if (picture.name.includes('3')) {
                  picturesToRequest[2] = setClaimPicture(picture);
                } else if (picture.name.includes('4')) {
                  picturesToRequest[3] = setClaimPicture(picture);
                }
              }

              peoplePhotos = peoplePhotos.concat(picturesToRequest);
              break;
            }
          }
        }
        personRequest.photos = peoplePhotos;
        //#endregion

        //#region Set Services
        personRequest.services = claimPerson.services.length === 0 ? [] : claimPerson.services.map(
          service => {
            let serviceItem = new Map();
            serviceItem.set('typeStatus', { id: service.type?.id!, name: service.type?.name! });

            if (service.description)
              serviceItem.set('description', service.description.trim());

            if (service.id)
              serviceItem.set('id', service.id);

            if (service.operatorName)
              serviceItem.set('operatorName', service.operatorName.trim());

            if (service.operatorPhone)
              serviceItem.set('operatorPhone', service.operatorPhone.getFullNumber());

            if (service.amount !== undefined)
              serviceItem.set('amount', service.amount);

            if (service.hasDeductible !== undefined)
              serviceItem.set('deductible', service.hasDeductible);

            if (service.itemNumber) 
              serviceItem.set('sheetNumber', service.itemNumber);

            if (service.category?.id) 
              serviceItem.set('categoryStatus', { id: service.category?.id!, name: service.category?.name! });
 
            if (service.coverage?.id) 
              serviceItem.set('coverageStatus', { id: service.coverage?.id!, name: service.coverage?.name! });
            
            serviceItem.set('previous', service.isPrior);

            if (service.provider && service.provider.id) {
              if (service.provider.isSubProvider) {
                serviceItem.set('subprovider', { id: service.provider.id, name: service.provider.name });
              } else {
                serviceItem.set('provider', { id: service.provider.id, name: service.provider.name });
              }
            }

            if (service.pictures) {
              let servicePhotos: IClaimPicture[] = [];
  
              for (const servicePicture of service.pictures) {
                servicePhotos.push(setClaimPicture(servicePicture));
              }

              serviceItem.set('photos', servicePhotos);
            }

            return Object.fromEntries(serviceItem);
          }
        );
        //#endregion

        //#region Set policy
        if (claimPerson.insuranceCompany) {
          personRequest.policy = {
            claimNumber: claimPerson.thirdInsuranceClaimNumber,
            insuranceProvider: 
              claimPerson.insuranceCompany.id ?
                {
                  id: claimPerson.insuranceCompany.id!,
                  name: claimPerson.insuranceCompany.name!,
                }
              :
                undefined,
						duaNumber: claimPerson.thirdInsuranceDuaNumber,
						adjusterName: claimPerson.thirdInsuranceAdjusterName,
            number: claimPerson.policyNumber,
            subsection: claimPerson.issueNumber
          }
        }
        //#endregion

        request.json.peoples?.push(personRequest);
      }
    );

    return request;
  }

  export const claimPersonInfoUpdateRequestAdapter = (key: string, claimPerson: ClaimRegister, completed?: boolean, newPictures?: ClaimRegisterPicture[], type?: TClaimPictureName): IClaimPersonInfoUpdateRequest => {
    let request: IClaimPersonInfoUpdateRequest = {
      id: claimPerson.formId,
      key: key,
      signature: claimPerson.signature,
      json: {
        completed: completed as boolean,
        started: true,
        id: claimPerson.claimId,
        declaration: claimPerson.facts ? claimPerson.facts.trim() : '',
        number: claimPerson.claimNumber,
        typeStatus: {
          name: claimPerson.claimType?.name!,
          id: claimPerson.claimType?.id!,
        },
        peoples: [
          {
            claim_id: claimPerson.claimId,
            id: claimPerson.person?.id!,
            first_name: claimPerson.person?.name ? claimPerson.person?.name.trim() : null!,
            first_last_name: claimPerson.person?.lastName ? claimPerson.person?.lastName.trim() : null!,
            second_last_name: claimPerson.person?.secondLastName ? claimPerson.person?.secondLastName.trim() : null!,
            email: claimPerson.person?.email ? claimPerson.person?.email.trim() : null!,
            phone: claimPerson.person?.profile?.phone?.getFullNumber(true),
            birthdate: claimPerson.person?.profile?.birthdate ? Util.TRANSFORM.DATE.formatServerDate(claimPerson.person.profile.birthdate) : null!,
            typeStatus: claimPerson.personType.id!,
            services: [],
          }
        ]
      }
    }

    if (claimPerson.circumstance) {
      request.json.peoples[0].circumstance = {
        id: claimPerson.circumstance.id,
        name: claimPerson.circumstance.name
      }
    }

    if (claimPerson.license) {
      request.json.peoples[0].license = {
        number: claimPerson.license?.number || null!,
        state: claimPerson.license?.stateName || null!,
        endDate: claimPerson.license?.endDate ? Util.TRANSFORM.DATE.formatServerDate(claimPerson.license.endDate) : null!,
        startDate: claimPerson.license?.startDate ? Util.TRANSFORM.DATE.formatServerDate(claimPerson.license.startDate) : null!,
        typeStatus: {
          id: claimPerson.license?.type?.id || null!,
          name: claimPerson.license?.type?.name || null!
        }
      }
    }

    if (claimPerson.person?.profile?.address?.postalCode) {
      request.json.peoples[0].address = {
        municipality: claimPerson.person?.profile?.address?.municipality ? claimPerson.person?.profile?.address?.municipality.trim() : null!,
        name: claimPerson.person?.profile?.address?.name ? claimPerson.person?.profile?.address?.name.trim() : null!,
        state: claimPerson.person?.profile?.address?.state || null!,
        postal_code: claimPerson.person?.profile?.address?.postalCode || null!,
        latitude: claimPerson.person.profile.address.latitude?.toString(),
        longitude: claimPerson.person.profile.address.longitude?.toString(),
      }
    }

    if (claimPerson.vehicleType) {
      request.json.peoples[0].vehicle = {
        id: claimPerson.vehicle?.id,
        colorStatus: {
          id: claimPerson.vehicleColor?.id || null!,
          name: claimPerson.vehicleColor?.name || null!
        },
        typeStatus: {
          id: claimPerson.vehicleType?.id || null!,
          name: claimPerson.vehicleType?.name || null!
        },
        brand: claimPerson.vehicle?.brand?.name ? claimPerson.vehicle?.brand?.name.trim() : undefined,
        model: claimPerson.vehicle?.model?.name ? claimPerson.vehicle?.model?.name.trim() : undefined,
        year: claimPerson.vehicle?.year?.value,
        plates: claimPerson.vehicle?.plate ? claimPerson.vehicle?.plate.trim() : undefined,
        seriesNumber: claimPerson.vehicle?.vin ? claimPerson.vehicle?.vin.trim() : undefined,
      }

      // Set Damages
      let damagesRequest = [];
      for (const damage of claimPerson.damages) {
        let vehicleDamagesPhotos: IClaimPicture[] = [];

        for (const dmgPicture of damage.pictures) {
          vehicleDamagesPhotos.push(setClaimPicture(dmgPicture));
        }

        damagesRequest.push(
          {
            id: damage.id,
            typeStatus: {
              id: damage.type.id,
              name: damage.type.name
            },
            previous: damage.isPrior,
            preexistence: damage.damagePreexistence,
            photos: vehicleDamagesPhotos,
            description: damage.description ? damage.description.trim() : undefined
          }
        );
      }
      request.json.peoples[0].vehicle.damages = damagesRequest;
      // End Set Damages
    }

    // Set request pictures
    let mergedPictures: ClaimRegisterPicture[] = [...claimPerson.licensePictures, ...claimPerson.inePictures, ...claimPerson.drivingPermissionPictures, ...claimPerson.vinPictures, ...claimPerson.vehiclePictures];
    let jsonPhotos: IClaimPicture[] = [];
    let peoplePhotos: IClaimPicture[] = [];

    for (const currentJsonPicture of claimPerson.referencePictures) {
      jsonPhotos.push(setClaimPicture(currentJsonPicture));
    }

    for (const currentPeoplePictures of mergedPictures) {
      peoplePhotos.push(setClaimPicture(currentPeoplePictures));
    }

    if (newPictures && type) {
      switch (type) {

        case 'references': {
          const dataPictures: IClaimPicture[] = [];
          for (const picture of newPictures) {
            dataPictures.push(setClaimPicture(picture))
          }
          jsonPhotos = jsonPhotos.concat(dataPictures);
          break;
        }

        case 'license': {
          let picturesToRequest: IClaimPicture[] = new Array(2);
          for (const picture of newPictures) {
            if (picture.type?.isPersonFrontLicensePicture()) {
              picturesToRequest[0] = setClaimPicture(picture);
            } else if (picture.type?.isPersonBackLicensePicture()) {
              picturesToRequest[1] = setClaimPicture(picture);
            } else if (picture.type?.isPersonFrontInePicture()) {
              picturesToRequest[0] = setClaimPicture(picture);
            } else if (picture.type?.isPersonBackInePicture()) {
              picturesToRequest[1] = setClaimPicture(picture);
            }
          }

          const missingPictureIndex = picturesToRequest.findIndex(p => p === undefined);
          if (missingPictureIndex > -1) {
            picturesToRequest[missingPictureIndex] = setClaimPicture(claimPerson.licensePictures[missingPictureIndex]);
          }

          peoplePhotos = peoplePhotos.concat(picturesToRequest);
          break;
        }

        case 'ine': {
          let picturesToRequest: IClaimPicture[] = new Array(2);
          for (const picture of newPictures) {
            if (picture.type?.isPersonFrontLicensePicture()) {
              picturesToRequest[0] = setClaimPicture(picture);
            } else if (picture.type?.isPersonBackLicensePicture()) {
              picturesToRequest[1] = setClaimPicture(picture);
            } else if (picture.type?.isPersonFrontInePicture()) {
              picturesToRequest[0] = setClaimPicture(picture);
            } else if (picture.type?.isPersonBackInePicture()) {
              picturesToRequest[1] = setClaimPicture(picture);
            }
          }

          const missingPictureIndex = picturesToRequest.findIndex(p => p === undefined);
          if (missingPictureIndex > -1) {
            picturesToRequest[missingPictureIndex] = setClaimPicture(claimPerson.inePictures[missingPictureIndex]);
          }

          peoplePhotos = peoplePhotos.concat(picturesToRequest);
          break;
        }

        case 'drivingPermission': {
          let picturesToRequest: IClaimPicture[] = [];
          picturesToRequest.push(setClaimPicture(newPictures[0]));
          peoplePhotos = peoplePhotos.concat(picturesToRequest);
          break;
        }

        case 'vin': {
          let picturesToRequest: IClaimPicture[] = [];
          picturesToRequest.push(setClaimPicture(newPictures[0]));
          peoplePhotos = peoplePhotos.concat(picturesToRequest);
          break;
        }

        case 'vehicle': {
          let picturesToRequest: IClaimPicture[] = new Array(4);
          for (const picture of newPictures) {
            if (picture.name.includes('1')) {
              picturesToRequest[0] = setClaimPicture(picture);
            } else if (picture.name.includes('2')) {
              picturesToRequest[1] = setClaimPicture(picture);
            } else if (picture.name.includes('3')) {
              picturesToRequest[2] = setClaimPicture(picture);
            } else if (picture.name.includes('4')) {
              picturesToRequest[3] = setClaimPicture(picture);
            }
          }

          peoplePhotos = peoplePhotos.concat(picturesToRequest);
          break;
        }
      }
    }

    request.json.photos = jsonPhotos;
    request.json.peoples[0].photos = peoplePhotos;
    // End Set request pictures

    return request;
  }

  /**
   * 
   * @param data 
   */
  export const claimAdjusterInfoDataAdapter = (data: IClaimAdjusterData): ClaimAdjuster => {
    const claimType: StatusTypeItem = new StatusTypeItem(data.response.json.typeStatus.id, data.response.json.typeStatus.name);
    let claimAdjuster: ClaimAdjuster = new ClaimAdjuster(data.response.json.adjuster?.name!, data.response.json.adjuster?.email!, data.response.id, data.response.json.id, data.response.json.number, data.response.json.reporting_name, data.response.json.declaration_cabin, claimType);
    const splitDateData = data.response.json.occurred_at.split(' ');
    // output example 2023-07-05T00:00:00+00:00 (Safari issue)
    const formatDateDate: string = `${splitDateData[0]}T${splitDateData[1]}${splitDateData[2].substring(0, 3)}:${splitDateData[2].substring(3, splitDateData[2].length)}`;

    claimAdjuster.date = new Date(formatDateDate);
    claimAdjuster.peopleFacts = data.response.json.declaration ? data.response.json.declaration : '';
    claimAdjuster.reportingPhone = new Phone();
    claimAdjuster.reportingPhone.number = data.response.json.reporting_phone;
    claimAdjuster.isFirstForm = data.response.json.firstForm;
    claimAdjuster.isFormStarted = data.response.json.started;
    claimAdjuster.isInvolvedsCompleted = data.response.json.peopleSectionConfirmed ? data.response.json.peopleSectionConfirmed : false;

    if (data.response.json.third_car_description) {
      claimAdjuster.vehicleInvolved = `${data.response.json.third_car_description.charAt(0).toUpperCase()}${data.response.json.third_car_description.slice(1)}`;
    }

    if (data.response.json.address) {
      let location: Address = new Address(data.response.json.address.postal_code);
      location.state = data.response.json.address.state!;
      location.municipality = data.response.json.address.municipality!;
      location.street = data.response.json.address.street!;
      location.latitude = parseFloat(data.response.json.address.latitude);
      location.longitude = parseFloat(data.response.json.address.longitude);
      if (data.response.json.address.reference) {
        location.genericReference = `${data.response.json.address.reference?.charAt(0).toUpperCase()}${data.response.json.address.reference?.slice(1)}`;
      }
      claimAdjuster.location = location;
    }

    claimAdjuster.declarationSignature = data.response.json.na_declaration_signature!;
    claimAdjuster.clousure = data.response.json.conclusion!;

    if (data.response.json.policyInfo) {
      const policy: Policy = new Policy(data.response.json.policyInfo.number, new Date(data.response.json.policyInfo.endDate), new Date(data.response.json.policyInfo.startDate));
      policy.id = data.response.json.policyInfo.id;
      policy.owner = new Person();
      policy.owner.name = data.response.json.policyInfo.policyHolder.firstName
      policy.owner.lastName = data.response.json.policyInfo.policyHolder.firstLastName
      policy.owner.secondLastName = data.response.json.policyInfo.policyHolder.secondLastName

      for (const coverageData of data.response.json.policyInfo.coverages) {
        let newCoverage: Coverage | undefined;
        newCoverage = new Coverage();
        newCoverage.type = coverageData.name;
        newCoverage.isEnabled = coverageData.enabled;
        newCoverage.isDeductible = !!coverageData.deductible;
        if (coverageData.deductible) {
          newCoverage.deductible = coverageData.deductible;
        }
        policy.coverages.push(newCoverage);
      }

      if (data.response.json.policyInfo.status.includes('ACTIVE'))
        policy.status = { active: true };
      else if (data.response.json.policyInfo.status.includes('CANCELLED'))
        policy.status = { canceled: true };
      else if (data.response.json.policyInfo.status.includes('EXPIRED'))
        policy.status = { expired: true };
      else if (data.response.json.policyInfo.status.includes('PENDING'))
        policy.status = { pending: true };
      else if (data.response.json.policyInfo.status.includes('ISSUED'))
        policy.status = { toBegin: true };

      claimAdjuster.policy = policy;
    }

    if (data.response.json.causeTypeStatus && data.response.json.causeTypeStatus.id && data.response.json.causeTypeStatus.name) {
      const cause: StatusTypeItem = new StatusTypeItem(data.response.json.causeTypeStatus.id, data.response.json.causeTypeStatus.name);
      claimAdjuster.cause = cause;
    }

    if (data.response.json.liabilityStatus && data.response.json.liabilityStatus.id && data.response.json.liabilityStatus.name) {
      const liability: StatusTypeItem = new StatusTypeItem(data.response.json.liabilityStatus.id, data.response.json.liabilityStatus.name);
      claimAdjuster.liability = liability;
    }

    if (data.response.json.cancelledReason && data.response.json.cancelledReason.id && data.response.json.cancelledReason.name) {
      const reason: StatusTypeItem = new StatusTypeItem(data.response.json.cancelledReason.id, data.response.json.cancelledReason.name);
      claimAdjuster.reason = reason;
    }

    for (const personData of data.response.json.peoples) {
      const personType: StatusTypeItem = new StatusTypeItem(personData.typeStatus.id, personData.typeStatus.name);
      let claimPerson: ClaimRegister = new ClaimRegister(data.response.id, data.response.json.id, personType);

      claimPerson = setClaimRegisterData(claimPerson, personData);
      claimPerson.signature = personData.signature;
      claimAdjuster.claimPeople.push(claimPerson);
    }

    if (data.response.json.photos) {
      let duaPictures: ClaimRegisterPicture[] = [];
      for (const pictureData of data.response.json.photos) {
        let picture: ClaimRegisterPicture = new ClaimRegisterPicture(pictureData.id, pictureData.link, pictureData.name);
        picture.type = new StatusTypeItem(pictureData.typeStatus.id, pictureData.typeStatus.name);
        if (picture.type.isReferencePictures()) {
          claimAdjuster.referencePictures.push(picture);
        } else if (picture.type.isDUAPicture()) {
          duaPictures.push(picture);
        }
      }

      if (Array.from(duaPictures.values()).every(p => p !== undefined)) {
        claimAdjuster.duaPicture = duaPictures;
      }
    }

    return claimAdjuster;
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const claimPersonInfoDataAdapter = (data: IClaimPersonData): ClaimRegister => {
    const claimType: StatusTypeItem = new StatusTypeItem(data.response.json.typeStatus.id, data.response.json.typeStatus.name);
    const personType: StatusTypeItem = new StatusTypeItem(data.response.typeStatus.id, data.response.typeStatus.name);
    let claimRegister: ClaimRegister = new ClaimRegister(data.response.id, data.response.json.id, personType);
    claimRegister.claimType = claimType;
    claimRegister.completed = data.response.json.completed;
    claimRegister.facts = data.response.json.declaration;
    claimRegister.claimNumber = data.response.json.number;

    claimRegister = setClaimRegisterData(claimRegister, data.response.json.peoples[0]);

    if (data.response.json.photos) {
      for (const referencesPicturesData of data.response.json.photos) {
        let picture: ClaimRegisterPicture = new ClaimRegisterPicture(referencesPicturesData.id, referencesPicturesData.link, referencesPicturesData.name);
        picture.type = new StatusTypeItem(referencesPicturesData.typeStatus.id, referencesPicturesData.typeStatus.name);
        claimRegister.referencePictures.push(picture);
      }
    }

    return claimRegister;
  }

  /**
   * 
   * @param id 
   * @param pictures 
   * @param type 
   */
  export const claimPictureRequestAdapter = (formId: string, pictures: string[], type: TClaimPictureName): FormData | undefined => {
    let formData = new FormData();
    pictures.forEach(
      (item, index) => {
        try {
          switch (type) {
            // References Pictures
            case 'references': {
              formData.append(`${Util.STATUS_CODE.CLAIM_PICTURES_TYPES.CLAIMS.NAMES.REFERENCES}/crucero-${index + 1}`, Util.TRANSFORM.FILE.dataURLtoFile(item));

              break;
            }
            // License Pictures
            case 'license': {
              if (index === 0) {
                formData.append(`${Util.STATUS_CODE.CLAIM_PICTURES_TYPES.PERSON.NAMES.LICENSE_FRONT_PICTURE}/licencia-delantera`, Util.TRANSFORM.FILE.dataURLtoFile(item));
              } else {
                formData.append(`${Util.STATUS_CODE.CLAIM_PICTURES_TYPES.PERSON.NAMES.LICENSE_BACK_PICTURE}/licencia-trasera`, Util.TRANSFORM.FILE.dataURLtoFile(item));
              }

              break;
            }
            // INE Pictures
            case 'ine': {
              if (index === 0) {
                formData.append(`${Util.STATUS_CODE.CLAIM_PICTURES_TYPES.PERSON.NAMES.INE_FRONT_PICTURE}/ine-delantero`, Util.TRANSFORM.FILE.dataURLtoFile(item));
              } else {
                formData.append(`${Util.STATUS_CODE.CLAIM_PICTURES_TYPES.PERSON.NAMES.INE_BACK_PICTURE}/ine-trasero`, Util.TRANSFORM.FILE.dataURLtoFile(item));
              }
              break
            }
            // Driving Permission Pictures
            case 'drivingPermission': {
              formData.append(`${Util.STATUS_CODE.CLAIM_PICTURES_TYPES.PERSON.NAMES.DRIVING}/tajeta-de-circulacion`, Util.TRANSFORM.FILE.dataURLtoFile(item));
              break
            }
            // VIN Pictures
            case 'vin': {
              formData.append(`${Util.STATUS_CODE.CLAIM_PICTURES_TYPES.PERSON.NAMES.VIN}/numero-de-serie`, Util.TRANSFORM.FILE.dataURLtoFile(item));
              break
            }
            // Vehicle Pictures
            case 'vehicle': {
              formData.append(`${Util.STATUS_CODE.CLAIM_PICTURES_TYPES.PERSON.NAMES.VEHICLE}/vehiculo-${index + 1}`, Util.TRANSFORM.FILE.dataURLtoFile(item));
              break;
            }
            // Damages Pictures
            case 'damages': {
              formData.append(`${Util.STATUS_CODE.CLAIM_PICTURES_TYPES.DAMAGE.NAMES.PICTURE}/daño-${index + 1}`, Util.TRANSFORM.FILE.dataURLtoFile(item));
              break;
            }
            // DUA Picture
            case 'dua': {
              formData.append(`${Util.STATUS_CODE.CLAIM_PICTURES_TYPES.CLAIMS.NAMES.DUA}/dua-${index + 1}`, Util.TRANSFORM.FILE.dataURLtoFile(item));
              break;
            }
						// Services Pictures
						case 'services': {
							formData.append(`${Util.STATUS_CODE.CLAIM_PICTURES_TYPES.SERVICE.NAMES.PICTURE}/servicio-${index + 1}`, Util.TRANSFORM.FILE.dataURLtoFile(item));
						}
          }

        } catch {
          console.warn('Discarded picture', item);
        }
      }
    );

    formData.append('objectId', formId); //ID DE FORM AHORA, CAMBIAR
    formData.append('objectType', Util.STATUS_CODE.CLAIM_PICTURES_TYPES.FORM.OBJECT_TYPE); // TIPO FORMULARIO

    return Array.from(formData.entries()).length > 2 ? formData : undefined;
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const claimPicturesDataAdapter = (data: IClaimPictureData): ClaimRegisterPicture[] => {
    let list: ClaimRegisterPicture[] = [];
    for (const pictureData of data.response) {
      let picture: ClaimRegisterPicture = new ClaimRegisterPicture(pictureData.id, pictureData.link, pictureData.name);
      picture.type = new StatusTypeItem(pictureData.typeStatus.id, pictureData.typeStatus.name);
      list.push(picture);
    }
    return list;
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const createClaimEduaFolRequestAdapter = (uid: string, folId?: string): IClaimEduaInfoRequest => {
    let eduaData: IClaimEduaInfoRequest = {
      id: uid
    };

    if (folId)
      eduaData.folId = folId;

    return eduaData;
  }

}