import { AxiosPromise } from "axios";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { IStatusTypesData } from "../interfaces/IStatusTypesData";
import { IClaimStatesData } from "../interfaces/IClaimStatesData";
import { IClaimStateByPostalCodeData } from "../interfaces/IClaimStateByPostalCodeData";
import { IClaimFormRequest } from "../interfaces/IClaimFormRequest";
import { IClaimPersonData } from "../interfaces/IClaimPersonData";
import { IClaimPersonInfoUpdateRequest } from "../interfaces/IClaimPersonInfoUpdateRequest";
import { IClaimPictureData } from "../interfaces/IClaimPictureData";
import { IClaimAdjusterData } from "../interfaces/IClaimAdjusterData";
import { IClaimServiceTypesRequest } from "../interfaces/IClaimServiceTypesRequest";
import { IClaimAdjusterInfoUpdateRequest } from "../interfaces/IClaimAdjusterInfoUpdateRequest";
import { IClaimProvidersRequest } from "../interfaces/IClaimProvidersRequest";
import { ICoveragesListRequest } from "../interfaces/ICoveragesListRequest";
import { IProvidersListData } from "../interfaces/IProvidersListData";
import { IClaimEduaInfoRequest } from "../interfaces/IClaimEduaInfoRequest";
import { ILiabilityListRequestParams } from "../interfaces/ILiabilityListRequestParams";
import { IPersonTypeListRequestParams } from "../interfaces/IPersonTypeListRequestParams";

/**
 *
 */
export namespace ClaimRegisterService {

  /**
   * 
   * @param typeId 
   * @param params 
   * @returns 
   */
  export const statusTypeService = (typeId: string, params?: IClaimServiceTypesRequest): AxiosPromise<IStatusTypesData> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).get(Util.API_PATH.CLAIM_STATUS_TYPE(typeId), { params: params });
  }

  /**
   * 
   * @param params 
   * @returns 
   */
  export const liabilityListService = (params: ILiabilityListRequestParams): AxiosPromise<IStatusTypesData> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).get(Util.API_PATH.CLAIMS_STATUS, { params: params });
  }

  /**
   * 
   * @param params 
   * @returns 
   */
  export const personListService = (params: IPersonTypeListRequestParams): AxiosPromise<IStatusTypesData> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).get(Util.API_PATH.CLAIMS_STATUS, { params: params });
  }

	/**
	 * 
	 * @param serviceType 
	 * @returns 
	 */
	export const categoriesListService = (serviceType: string): AxiosPromise<IStatusTypesData> => {
		return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).get(Util.API_PATH.CLAIMS_CATEGORIES_LIST(serviceType));
	}
	
  /**
   * 
   * @param params 
   * @returns 
   */
	export const coveragesTypeService = (params: ICoveragesListRequest): AxiosPromise<IStatusTypesData> => {
		return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).get(Util.API_PATH.CLAIMS_STATUS, { params: params });
	}

  /**
   * 
   * @returns 
   */
	export const causesListService = (): AxiosPromise<IStatusTypesData> => {
		return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).get(Util.API_PATH.CLAIM_STATUS_CAUSE);
	}

	/**
	 * 
	 * @param serviceType 
	 * @param category 
	 * @returns 
	 */
	export const providersListService = (params: IClaimProvidersRequest): AxiosPromise<IProvidersListData> => {
		return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).get(Util.API_PATH.CLAIMS_PROVIDERS, { params: params });
	}

  /**
   * 
   * @returns 
   */
  export const countryStatesService = (): AxiosPromise<IClaimStatesData> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).get(Util.API_PATH.CLAIM_STATES);
  }

  /**
   * 
   * @param postalCode 
   * @returns 
   */
  export const countryStateByPostalCodeService = (postalCode: string): AxiosPromise<IClaimStateByPostalCodeData> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).get(Util.API_PATH.CLAIM_STATES_BY_POSTAL_CODE(postalCode));
  }

  /**
   * 
   * @param request 
   * @returns 
   */
  export const claimPersonInfoUpdateService = (request: IClaimPersonInfoUpdateRequest): AxiosPromise<IClaimPersonData> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).put(Util.API_PATH.CLAIM_PERSON_INFO(), request);
  }

  /**
   * 
   * @param request 
   * @returns 
   */
  export const claimAdjusterInfoUpdateService = (request: IClaimAdjusterInfoUpdateRequest): AxiosPromise<IClaimAdjusterData> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).put(Util.API_PATH.CLAIM_ADJUSTER_INFO(), request);
  }

  /**
   * 
   * @param request 
   * @returns 
   */
  export const claimPictureService = (request: FormData): AxiosPromise<IClaimPictureData> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).post(Util.API_PATH.CLAIM_PICTURES, request);
  }

  /**
   * 
   * @param request 
   * @returns 
   */
  export const claimPersonInfoService = (request: IClaimFormRequest): AxiosPromise<IClaimPersonData> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).get(Util.API_PATH.CLAIM_PERSON_INFO(request.id, request.key));
  }

  /**
   * 
   * @param request 
   * @returns 
   */
  export const claimAdjusterInfoService = (request: IClaimFormRequest): AxiosPromise<IClaimAdjusterData> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).get(Util.API_PATH.CLAIM_ADJUSTER_INFO(request.id, request.key) );
  }

  /**
   * 
   * @returns 
   */
  export const createClaimEduaFolService = (data: IClaimEduaInfoRequest): AxiosPromise<IClaimAdjusterData> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).post(Util.API_PATH.CREATE_EDUA_QR, data);
  }

  /**
   * 
   * @returns 
   */
  export const vinculateClaimEduaFolService = (data: IClaimEduaInfoRequest): AxiosPromise<IClaimAdjusterData> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).post(Util.API_PATH.VINCULATE_EDUA_QR, data);
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const syncClaimInvolvedsService = (data: IClaimEduaInfoRequest): AxiosPromise<void> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).post(Util.API_PATH.SYNC_EDUA_DATA, data);
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const getSynchronizedClaimService = (data: IClaimEduaInfoRequest): AxiosPromise<IClaimAdjusterData> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).post(Util.API_PATH.GET_SYNCHRONIZED_DATA, data);
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const refolClaimService = (data: IClaimEduaInfoRequest): AxiosPromise<IClaimAdjusterData> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).post(Util.API_PATH.REFOL_CLAIM, data);
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const issueDigitalVoucherService = (data: IClaimEduaInfoRequest): AxiosPromise<void> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).post(Util.API_PATH.ISSUE_DIGITAL_VOUCHER, data);
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const receiveDigitalVoucherService = (data: IClaimEduaInfoRequest): AxiosPromise<void> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).post(Util.API_PATH.RECEIVE_DIGITAL_VOUCHER, data);
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const generateAmisOrderService = (data: IClaimEduaInfoRequest): AxiosPromise<void> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).post(Util.API_PATH.GENERATE_AMIS_ORDER, data);
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const endAmisAttentionService = (data: IClaimEduaInfoRequest): AxiosPromise<void> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).post(Util.API_PATH.END_AMIS_ATTENTION, data);
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const ajusterInformService = (data: IClaimEduaInfoRequest): AxiosPromise<void> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.DEFENDER_API).post(Util.API_PATH.GENERATE_ADJUSTER_INFORM, data);
  }
}