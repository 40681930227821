import { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingFactory } from "src/factory/loading.factory";
import { CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS, CLAIM_REGISTER_PATH } from "src/routes/paths/claim-register.paths";
import { IClaimPersonSyncForm } from "../interfaces/IClaimPersonSyncForm";
import { useDispatch, useSelector } from "react-redux";
import { ClaimRegisterSelector } from "../selectors/claim-register.selector";
import { ClaimRegisterFeature } from "../features/claim-register.feature";
import { ClaimRegisterAction } from "../actions/claim-register.action";
import { AlertFactory } from "src/factory/alert.factory";
import { ClaimAdjuster } from "src/classes/claims/ClaimAdjuster";

export type PERSON_SYNC_SECTION = 'syncManagement' | 'generateEDUASheet' | 'importEDUASheet' | 'syncResults';

/**
 *
 */
export namespace ClaimPersonSyncFacade {

  /**
   * 
   */
  export const SYNC_SECTIONS = {
    SYNC_MANAGEMENT: 'syncManagement',
    GENERATE_EDUA_SHEET: 'generateEDUASheet',
    IMPORT_EDUA_SHEET: 'importEDUASheet',
    //SYNC_RESULTS: 'syncResults'
  }

  /**
   * 
   */
  export const useClaimPersonSync = () => {
    const adjusterInfo = useSelector(ClaimRegisterSelector.adjusterInfo);
    const [currentSection, setCurrentSection] = useState<PERSON_SYNC_SECTION>('syncManagement');
    const [showQRReader, setShowQRReader] = useState<boolean>(false);
    const [decodedQRCode, setDecodedQRCode] = useState<string>();
		const [showRefolModal, setShowRefolModal] = useState<boolean>(false);
    const [showAmisErrorModal, setShowAmisErrorModal] = useState<boolean>(false);
    const [amisErrorMode, setAmisErrorMode] = useState<'emit' | 'receive'>();
    const [folId, setFolId] = useState<string>();
    const { sectionName, formId, key } = useParams<string>();
    const loading = LoadingFactory.useLoader();
    const amisLoading = LoadingFactory.useLoader();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    /**
     * 
     * @param code 
     */
    const onCodeDecoded = (code: string): void => {
      const formattedCode = code.split('|')[0];
      setDecodedQRCode(formattedCode);
      setShowQRReader(false);
      loading.set(true);
      vinculateClaimEduaFol(formattedCode);
    }

    /**
     * 
     */
    const backNavigationHandler = useCallback((navigateOut?: boolean) => {
      if (currentSection === SYNC_SECTIONS.SYNC_MANAGEMENT || navigateOut)
        navigate(`${CLAIM_REGISTER_PATH.ADJUSTER}/${sectionName}/${formId}/${key}/person/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.PERSONS_LIST}`);
      else if (currentSection === SYNC_SECTIONS.GENERATE_EDUA_SHEET || currentSection === SYNC_SECTIONS.IMPORT_EDUA_SHEET)
        setCurrentSection(SYNC_SECTIONS.SYNC_MANAGEMENT as PERSON_SYNC_SECTION);
      else 
        navigate('');
    }, [currentSection, sectionName, formId, key, navigate])

    /**
     * 
     */
    const createClaimEduaFol = async() => {
      try {
        if (!adjusterInfo)
          return;
  
        loading.set(true);
        let incomingAdjusterInfo: ClaimAdjuster | undefined = undefined;

        if (!adjusterInfo.hasFolId())
          incomingAdjusterInfo = await ClaimRegisterFeature.createClaimEduaFolFeature(adjusterInfo.formId);
        
        if (incomingAdjusterInfo) {
          const _folId = incomingAdjusterInfo.claimPeople[0].folId;
            setFolId(_folId);
          //incomingAdjusterInfo.isInvolvedsCompleted = false;
          await updateAdjusterInfo(false, incomingAdjusterInfo);
          dispatch(ClaimRegisterAction.setNewClaimAdjusterInfo(incomingAdjusterInfo));
        }

        await synchronize(true);
        
        setCurrentSection(SYNC_SECTIONS.GENERATE_EDUA_SHEET as PERSON_SYNC_SECTION);
      } catch (e) {
        console.error(e);
        AlertFactory.errorAlert((e as Error).message);
      } finally {
        loading.set(false);
      }
    };

    /**
     * 
     */
    const synchronize = async(avoidSynchronizeCallback?: boolean) => {
      try {
        if (!adjusterInfo || !formId || !key)
          return;

        await ClaimRegisterFeature.syncClaimEduaFeature(adjusterInfo.formId);

        if (!avoidSynchronizeCallback) {
          const incomingAdjusterInfo = await ClaimRegisterFeature.getSynchronizedClaimFeature(adjusterInfo.formId);
  
          if (incomingAdjusterInfo)
            dispatch(ClaimRegisterAction.setNewClaimAdjusterInfo(incomingAdjusterInfo));
        }
      } catch (e) {
        console.error(e);
        throw(e);
      } 
    }

    /**
     * 
     * @returns 
     */
    const refolClaim = async(): Promise<void> => {
      return new Promise(
        async(resolve, reject) => {
          try {
            if (!adjusterInfo || !formId || !key || adjusterInfo.isInvolvedsCompleted)
              return;
            
            loading.set(true);
            const incomingAdjusterInfo = await ClaimRegisterFeature.refolClaimFeature(adjusterInfo.formId);
            await updateAdjusterInfo(true, incomingAdjusterInfo);
            resolve();
          } catch (e) {
            console.error(e);
            AlertFactory.errorAlert((e as Error).message);
            reject();
          } finally {
            setShowRefolModal(false);
            loading.set(false);
          }
        }
      )
    }

    /**
     * 
     */
    const syncAndRedirect = async() => {
      try {
        loading.set(true);
        await synchronize();
        backNavigationHandler(true);
      } catch (e) {
        console.error(e);
        AlertFactory.errorAlert((e as Error).message);
      } finally {
        loading.set(false);
      }
    }

    /**
     * 
     * @returns 
     */
    const updateAdjusterInfo = async(peopleSectionConfirmed?: boolean, incomingAdjusterInfo?: ClaimAdjuster): Promise<void> => {
      return new Promise(async(resolve, reject) => {
        try {
          if (!key || (!adjusterInfo && !incomingAdjusterInfo))
            return;

          let currentAdjusterInfo: ClaimAdjuster | undefined = incomingAdjusterInfo ? incomingAdjusterInfo : adjusterInfo;

          if (peopleSectionConfirmed !== undefined && currentAdjusterInfo) {
            currentAdjusterInfo.setInvolvedsSectionCompleted(peopleSectionConfirmed);

            const newAdjusterInfo = await ClaimRegisterFeature.claimAdjusterInfoUpdateFeature(key, currentAdjusterInfo, false);

            if (newAdjusterInfo)
              dispatch(ClaimRegisterAction.setNewClaimAdjusterInfo(newAdjusterInfo));
          }

          resolve();
        } catch (e) {
          console.error(e);
          AlertFactory.errorAlert((e as Error).message);
          reject(e);
        }
      })
		}

    /**
     * 
     * @returns 
     */
    /** 
      const amisServicesHandler = async(skipVoucher?: boolean): Promise<void> => {
        return new Promise(async(resolve, reject) => {
          try {
            loading.set(true);

            if (adjusterInfo && !adjusterInfo?.hasFolId()) {
              resolve();
              return;
            }

            if (!skipVoucher) {
              const voucherMode = adjusterInfo && adjusterInfo.checkLiability();

              if (voucherMode === 'emit') {
                const isAbleToProceed = adjusterInfo?.checkServiceIssuedInvolveds();
      
                if (!isAbleToProceed) {
                  setAmisErrorMode(voucherMode);
                  setShowAmisErrorModal(true);
                  reject();
                } 
              }

    
              if (voucherMode)
                await issueDigitalVoucher(voucherMode);
    
              if (voucherMode === 'emit')
                await amisServices('generateOrder');
            }

            await amisServices('endAttention');
            await amisServices('adjusterInform');
            resolve();
          } catch (e) {
            if (e) {
              console.error(e);
              AlertFactory.errorAlert((e as Error).message);
              reject();
            }
          } finally {
            loading.set(false);
          }
        })
      } 
    */

    /**
     * 
     * @param skipVoucher 
     * @returns 
     */
    const amisServicesHandler = async(): Promise<void> => {
      return new Promise(async(resolve, reject) => {
        try {
          loading.set(true);

          if (adjusterInfo && !adjusterInfo?.hasFolId()) {
            resolve();
            return;
          }

          const voucherMode = adjusterInfo && adjusterInfo.checkLiability();

          //If itsn't emit or receive it'll finish form.
          if (!voucherMode) {
            await finishEduaFlow();
            resolve();
            return;
          }

          if (voucherMode === 'emit') {
            const isAbleToProceed = adjusterInfo?.checkServiceIssuedInvolveds();
  
            if (!isAbleToProceed) {
              setAmisErrorMode(voucherMode);
              setShowAmisErrorModal(true);
              reject();
              return;
            } 
          }

          if (voucherMode)
            await issueDigitalVoucher(voucherMode);

          if (voucherMode === 'emit')
            await amisServices('generateOrder');
          
          await finishEduaFlow();
          resolve();
        } catch (e) {
          if (e) {
            console.error(e);
            AlertFactory.errorAlert((e as Error).message);
            reject();
          }
        } finally {
          loading.set(false);
        }
      })
    } 

    /**
     * 
     */
    const finishEduaFlow = async(): Promise<void> => {
      return new Promise(async(resolve, reject) => {
        try {
          loading.set(true);
          await amisServices('endAttention');
          await amisServices('adjusterInform');
          resolve();
        } catch (e) {
          if (e) {
            console.error(e);
            AlertFactory.errorAlert((e as Error).message);
            reject();
          }
        } finally {
          loading.set(false);
        }
      });
    }

    /**
     * 
     */
    const issueDigitalVoucher = async(voucherMode: 'emit' | 'receive'): Promise<void> => {
      return new Promise(async(resolve, reject) => {
        try {
          if (!adjusterInfo)
            return;
  
          loading.set(true);
          await ClaimRegisterFeature.issueDigitalVoucherFeature(adjusterInfo.formId, voucherMode);
          resolve();
        } catch (e) {
          console.error(e);

          const isAbleToProceed = adjusterInfo?.checkServiceIssuedInvolveds();
          if (isAbleToProceed)
            AlertFactory.errorAlert((e as Error).message);

          if (voucherMode === 'receive') {
            setAmisErrorMode(voucherMode);
            setShowAmisErrorModal(true);
          }

          reject(e);
        } finally {
          loading.set(false);
        }
      })
    }

    /**
     * 
     * @param service 
     * @returns 
     */
    const amisServices = async(service: 'generateOrder' | 'endAttention' | 'adjusterInform') => {
      try {
        if (!adjusterInfo)
          return;

        await ClaimRegisterFeature.amisServiceFeatures(adjusterInfo.formId, service);
      } catch (e) {
        const error = e as Error;
        console.error(e);
        AlertFactory.errorAlert(error.message);
        throw(e);
      }
    }

    /**
     * 
     * @returns 
     */
    const vinculateClaimEduaFol = async(incomingFolId?: string) => {
      try {
        if (!adjusterInfo)
          return;
  
        loading.set(true);
        let _folId = (decodedQRCode || incomingFolId) as string;
        
        if (!adjusterInfo.claimPeople[0].folId) {
          const incomingAdjusterInfo = await ClaimRegisterFeature.createClaimEduaFolFeature(adjusterInfo.formId);

          if (incomingAdjusterInfo)
            dispatch(ClaimRegisterAction.setNewClaimAdjusterInfo(incomingAdjusterInfo));
        }

        await ClaimRegisterFeature.vinculateClaimEduaFolFeature(adjusterInfo.formId, _folId);
        await synchronize();
        backNavigationHandler(true);
      } catch (e) {
        console.error(e);
        AlertFactory.errorAlert((e as Error).message);
      } finally {
        loading.set(false);
      }
    };

    /**
     * 
     */
    const involvedRedirect = async() => {
      try {
        loading.set(true);
        await synchronize();
        backNavigationHandler(true);
      } catch (e) {
        console.error(e);
        AlertFactory.errorAlert((e as Error).message);
      } finally {
        loading.set(false);
      }
    }

    /**
     * 
     */
    const copyFolId = (): void => {
      if (folId) {
        navigator.clipboard.writeText(folId);
        AlertFactory.successAlert('Se ha copiado el número de folio al portapapeles.');
      } else
        AlertFactory.errorAlert('Ha ocurrido un error al intentar copiar el número de folio.');
    };

    /**
     * 
     */
    useEffect(
      () => {
        if (adjusterInfo) {
          const _folId = adjusterInfo.claimPeople[0].folId ? adjusterInfo.claimPeople[0].folId : folId;
          setFolId(_folId);
        }
      }, [adjusterInfo, folId]
    );

    return { 
      states: {
        adjusterInfo,
        currentSection,
        setCurrentSection,
        showQRReader,
        setShowQRReader,
        decodedQRCode,
        setDecodedQRCode,
        folId,
        showRefolModal,
        setShowRefolModal,
        showAmisErrorModal,
        setShowAmisErrorModal,
        amisErrorMode
      },
      loading,
      amisLoading,
      backNavigationHandler,
      onCodeDecoded,
      createClaimEduaFol,
      vinculateClaimEduaFol,
      involvedRedirect,
      copyFolId,
      syncAndRedirect,
      synchronize,
      refolClaim,
      amisServicesHandler,
      updateAdjusterInfo,
      finishEduaFlow
    }
  }

  /**
   * 
   */
  export const useClaimPersonSyncForm = () => {
    const form = useForm<IClaimPersonSyncForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
          sheetNumber: undefined,
        }
      }
    );

    return {
      form
    }
  }
}