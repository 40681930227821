import { CLAIM_REGISTER_PERSON_PARAMS } from "src/routes/paths/claim-register.paths";
import { Button, ButtonBack, Checkbox, Dropdown, InputArea, InputDate, InputLabel, InputPhone, Logo, Radio, Text } from "src/components";
import { ClaimPersonFacade } from "src/modules/claim-register/controller/facades/claim-person.facade";
import { IconFactory } from "src/factory/icon.factory";
import { ClaimPicturesFacade } from "src/modules/claim-register/controller/facades/claim-person-pictures.facade";
import { Controller } from "react-hook-form";
import { Util } from "src/utils/Util";
import { MapFactory } from "src/factory/map.factory";
import ClaimContent from "../../components/ClaimContent/ClaimContent";
import ClaimPicture from "../../components/ClaimPicture/ClaimPicture";
import ClaimDamage from "../../components/ClaimDamage/ClaimDamage";
import ClaimSummary from "../../components/ClaimSummary/ClaimSummary";
import ClaimSignature from "../../components/ClaimSignature/ClaimSignature";
import './ClaimPerson.scss';

/**
 *
 */
const ClaimPersonPage = (): JSX.Element => {
  const { currentStep, pageName, contentRef, changeStep, setShowDamage, showDamage, getStepsListByFormType } = ClaimPersonFacade.useClaimPerson();
  const { form, isExternalForm, formAddress, formLicense, registerInfo, states, list, formDamages, sendCurrentRegisterInfo, updating } = ClaimPersonFacade.useClaimPersonForm();
  const referencePictures = ClaimPicturesFacade.useClaimPictures(undefined, registerInfo?.referencePictures);
  const licensePictures = ClaimPicturesFacade.useClaimPictures(2, registerInfo?.licensePictures);
  const inePictures = ClaimPicturesFacade.useClaimPictures(2, registerInfo?.inePictures);
  const drivingPermissionPicture = ClaimPicturesFacade.useClaimPictures(undefined, registerInfo?.drivingPermissionPictures);
  const vinPictures = ClaimPicturesFacade.useClaimPictures(1, registerInfo?.vinPictures);
  const vehiclePictures = ClaimPicturesFacade.useClaimPictures(4, registerInfo?.vehiclePictures);

  return (
    <div className="claimPerson">
      {
        pageName &&
        <>
          {
            currentStep > 0 &&
            <div className="claimPerson__header">
              <div className="claimPerson__header__left">
                {
                  currentStep > 1 &&
                  <ButtonBack button to="" onClick={() => changeStep('backward')} />
                }
              </div>
              <div className="claimPerson__header__progress">
                {
                  getStepsListByFormType() &&
                  <div className="claimPerson__header__progress__bar" style={{ width: `${(currentStep * 100) / getStepsListByFormType()?.length!}%` }}>
                  </div>
                }

              </div>

              <div className="claimPerson__header__right">
              </div>
            </div>
          }


          {
            pageName === CLAIM_REGISTER_PERSON_PARAMS.WELCOME &&
            <ClaimContent title="">
              <div className="claimPerson__content">

                <div className="claimPerson__content__welcome">
                  <Text type="subtitle" align="center" className="claimPerson__content__welcome__message">¡Hola, <b>{registerInfo?.person?.name}</b>!</Text>

                  <Text type="title" align="center" className="claimPerson__content__welcome__title">Esperemos que te encuentres bien.</Text>

                  <Text type="subtitle" align="center" className="claimPerson__content__welcome__message">Por favor ayúdanos rellenando los siguientes datos.</Text>

                  {
                    !isExternalForm &&
                    <form className="claimPerson__content__welcome__form">
                      <div className="claimPerson__content__welcome__form__field">
                        <Controller
                          control={form.control}
                          name="privacy"
                          rules={
                            {
                              required: true,
                            }
                          }
                          render={({ field: { onChange } }) => (
                            <Checkbox
                              defaultChecked={form.getValues('privacy')}
                              onChange={(e) => onChange(e.target.checked)}
                            >
                              He le&iacute;do y acepto el <a target="_blank" href="https://www.crabi.com/legal/aviso-de-privacidad" rel="noreferrer">Aviso de Privacidad</a> de <b>Crabi</b>.
                            </Checkbox>
                          )}
                        />
                      </div>
                    </form>
                  }

                  <Button type="button" className="claimPerson__content__welcome__button"
                    disabled={!form.formState.isValid || updating.value}
                    loading={updating.value}
                    onClick={() => sendCurrentRegisterInfo(false).then(() => changeStep('forward'))}
                  >
                    Iniciar
                  </Button>
                </div>

              </div>
            </ClaimContent>
          }
          {
            pageName === CLAIM_REGISTER_PERSON_PARAMS.NAME &&
            <ClaimContent
              title="Necesitamos saber los datos del conductor"
            >
              <div className="claimPerson__content">
                <form className="claimPerson__content__form">
                  <div className="claimPerson__content__form__field">
                    <Controller
                      control={form.control}
                      name="name"
                      rules={
                        {
                          required: true,
                          pattern: Util.PATTERN.PERSON_NAMES
                        }
                      }
                      render={({ field: { onChange } }) => (
                        <InputLabel
                          errors={!!form.formState.errors.name && form.formState.dirtyFields.name}
                          maxLength={200}
                          defaultValue={form.getValues('name')}
                          onChange={(e) => onChange(e.target.value)}
                        >
                          Nombre
                        </InputLabel>
                      )}
                    />
                  </div>
                  <div className="claimPerson__content__form__field">
                    <Controller
                      control={form.control}
                      name="lastName"
                      rules={
                        {
                          required: true,
                          pattern: Util.PATTERN.PERSON_NAMES
                        }
                      }
                      render={({ field: { onChange } }) => (
                        <InputLabel
                          errors={!!form.formState.errors.lastName && form.formState.dirtyFields.lastName}
                          maxLength={200}
                          defaultValue={form.getValues('lastName')}
                          onChange={(e) => onChange(e.target.value)}
                        >
                          Apellido paterno
                        </InputLabel>
                      )}
                    />
                  </div>
                  <div className="claimPerson__content__form__field">
                    <Controller
                      control={form.control}
                      name="secondLastName"
                      rules={
                        {
                          required: true,
                          pattern: Util.PATTERN.PERSON_NAMES
                        }
                      }
                      render={({ field: { onChange } }) => (
                        <InputLabel
                          errors={!!form.formState.errors.secondLastName && form.formState.dirtyFields.secondLastName}
                          maxLength={200}
                          defaultValue={form.getValues('secondLastName')}
                          onChange={(e) => onChange(e.target.value)}
                        >
                          Apellido materno
                        </InputLabel>
                      )}
                    />
                  </div>

                  {
                    !isExternalForm &&
                    <div className="claimPerson__content__form__field">
                      <Controller
                        control={form.control}
                        name="birthdate"
                        rules={
                          {
                            required: true,
                          }
                        }
                        render={({ field: { onChange } }) => (
                          <InputDate
                            errors={(!!form.formState.errors.birthdate && form.formState.dirtyFields.birthdate)}
                            defaultValue={form.getValues('birthdate') ? form.getValues('birthdate') : ''}
                            onSelectDate={(date) => { onChange(date.toDateString()); form.trigger('birthdate') }}
                            position="top"
                          >
                            Fecha de nacimiento
                          </InputDate>
                        )}
                      />
                    </div>
                  }
                </form>

                <div className="claimRegister__button-wrapper">
                  <Button type="button"
                    disabled={!form.formState.isValid || updating.value}
                    loading={updating.value}
                    onClick={() => sendCurrentRegisterInfo(false).then(() => changeStep('forward'))}
                  >
                    Siguiente
                  </Button>
                </div>

              </div>
            </ClaimContent>
          }

          {
            pageName === CLAIM_REGISTER_PERSON_PARAMS.CONTACT &&
            <ClaimContent
              title="Necesitamos saber los datos de contacto del conductor"
            >
              <div className="claimPerson__content">
                <form className="claimPerson__content__form">
                  <div className="claimPerson__content__form__field">
                    <Controller
                      control={form.control}
                      name="email"
                      rules={
                        {
                          required: true,
                          pattern: Util.PATTERN.EMAIL
                        }
                      }
                      render={({ field: { onChange } }) => (
                        <InputLabel
                          type="email"
                          placeholder="micorreo@correo.com"
                          errors={!!form.formState.errors.email && form.formState.dirtyFields.email}
                          maxLength={200}
                          defaultValue={form.getValues('email')}
                          onChange={(e) => onChange(e.target.value)}
                        >
                          Correo electr&oacute;nico
                        </InputLabel>
                      )}
                    />
                  </div>
                  <div className="claimPerson__content__form__field">
                    <Controller
                      control={form.control}
                      name="phone"
                      rules={
                        {
                          required: true,
                          pattern: Util.PATTERN.NUMBERS,
                          maxLength: 10,
                          minLength: 10
                        }
                      }
                      render={({ field: { onChange } }) => (
                        <InputPhone
                          lockCountryCode
                          placeholder="Teléfono con Whatsapp"
                          errors={!!form.formState.errors.phone && form.formState.dirtyFields.phone}
                          defaultValue={form.getValues('phone')}
                          onCountryCodeSelected={(code) => form.setValue('countryCode', code)}
                          onChange={(e) => onChange(e.target.value)}
                        >
                          Tel&eacute;fono
                        </InputPhone>
                      )}
                    />

                  </div>
                </form>

                <div className="claimRegister__button-wrapper">
                  <Button type="button"
                    disabled={!form.formState.isValid || updating.value}
                    loading={updating.value}
                    onClick={() => sendCurrentRegisterInfo(false).then(() => changeStep('forward'))}
                  >
                    Siguiente
                  </Button>
                </div>

              </div>
            </ClaimContent>
          }

          {
            pageName === CLAIM_REGISTER_PERSON_PARAMS.DESCRIPTION &&
            <ClaimContent
              title="¿Nos podrías narrar lo que sucedió en el accidente?"
              subtitle="Puedes añadir todos los detalles que tú consideres"
            >
              <div className="claimPerson__content">
                <form className="claimPerson__content__form">
                  <div className="claimPerson__content__form__field">
                    <Controller
                      control={form.control}
                      name="facts"
                      rules={
                        {
                          required: true,
                        }
                      }
                      render={({ field: { onChange } }) => (
                        <InputArea maxLength={1000} rows={8}
                          errors={!!form.formState.errors.facts && form.formState.dirtyFields.facts}
                          defaultValue={form.getValues('facts')}
                          onChange={(e) => onChange(e.target.value)}
                          name="facts"
                        />
                      )}
                    />
                  </div>
                </form>

                <div className="claimRegister__button-wrapper">
                  <Button type="button"
                    disabled={!form.formState.isValid || updating.value}
                    loading={updating.value}
                    onClick={() => sendCurrentRegisterInfo(false).then(() => changeStep('forward'))}
                  >
                    Siguiente
                  </Button>
                </div>

              </div>
            </ClaimContent>
          }

          {
            pageName === CLAIM_REGISTER_PERSON_PARAMS.GENERIC_PICTURES &&
            <ClaimContent
              title="¿Podrías tomar fotos de la zona del accidente?"
              subtitle="Fotos de la calle, señalizaciones y referencias cercanas (más adelante se suben las fotos del vehículo)"
            >
              <div className="claimPerson__content">
                <div className="claimPerson__content__form">
                  {
                    referencePictures.pictures.map(
                      (src, index) => (
                        <div key={`description-image-${index}`}>
                          <ClaimPicture cancelEdit defaultPicture={src} onSelected={(picture) => referencePictures.addPicture(picture, index)} />
                          <Text type="label" align="center" block bold>Foto #{index + 1}</Text>
                        </div>
                      )
                    )
                  }

                  <div className="claimPerson__content__form__add-button">
                    <button type="button" onClick={() => referencePictures.newPicture()}>
                      {IconFactory.addIcon()}
                    </button>
                    <Text type="label" bold align="center">A&ntilde;adir otra imagen</Text>
                  </div>
                </div>

                <div className="claimRegister__button-wrapper">
                  <Button type="button"
                    disabled={updating.value}
                    loading={updating.value}
                    onClick={() => sendCurrentRegisterInfo(false, referencePictures.pictures, 'references').then(() => changeStep('forward'))}
                  >
                    Siguiente
                  </Button>
                </div>

              </div>
            </ClaimContent>
          }

          {
            pageName === CLAIM_REGISTER_PERSON_PARAMS.LOCATION &&
            <ClaimContent
              title={!isExternalForm ? `¿Cuál es el domicilio particular del conductor?` : 'Información de domicilio'}
              subtitle={!isExternalForm ? `¿Es el mismo de la contratación?` : ''}
            >
              <div className="claimPerson__content">
                <form className="claimPerson__content__form">
                  {
                    !isExternalForm &&
                    <>
                      <div className="claimPerson__content__form__field claimPerson__content__form__field--center">
                        <Controller
                          control={form.control}
                          name="savedAddress"
                          defaultValue={true}
                          rules={{
                            required: false
                          }}
                          render={({ field: { onChange } }) => (
                            <>
                              <Radio
                                defaultChecked={form.getValues('savedAddress')}
                                name="savedAddress"
                                onChange={() => { onChange(true); formAddress.setShowUserAddress(true) }}>
                                Sí
                              </Radio>
                              <Radio
                                defaultChecked={!form.getValues('savedAddress')}
                                name="savedAddress"
                                onChange={() => { onChange(false); formAddress.setShowUserAddress(false) }}>
                                No
                              </Radio>
                            </>
                          )}
                        />
                      </div>
                    </>
                  }

                  {
                    !formAddress.showUserAddress || isExternalForm ?
                      <>

                        {
                          !isExternalForm &&
                          <div className="claimPerson__content__form__field">
                            <MapFactory.Map label="Dirección"
                              onSelect={(address) => formAddress.onSelectAddress(address)}
                              defaultCoords={{ lat: formAddress.currentAddress?.latitude!, lng: formAddress.currentAddress?.longitude! }} 
                            />
                          </div>
                        }

                        <div className="claimPerson__content__form__field">
                          <Controller
                            control={form.control}
                            name="postalCode"
                            rules={
                              {
                                required: true,
                                pattern: Util.PATTERN.NUMBERS,
                                maxLength: 8
                              }
                            }
                            render={({ field: { onChange } }) => (
                              <InputLabel
                                errors={!!form.formState.errors.postalCode && form.formState.dirtyFields.postalCode}
                                defaultValue={form.getValues('postalCode')}
                                key={`postal-code-${formAddress.loadingPostalCodeInfo.value}`}
                                disabled={!isExternalForm}
                                maxLength={8}
                                onChange={(e) => { onChange(e.target.value); form.trigger('postalCode') }}
                                number
                              >
                                C&oacute;digo postal
                              </InputLabel>
                            )}
                          />
                        </div>

                        <div className="claimPerson__content__form__field">
                          <Controller
                            control={form.control}
                            name="municipality"
                            rules={
                              {
                                required: true,
                              }
                            }
                            render={() => (
                              <InputLabel disabled
                                value={form.getValues('municipality')}
                                loading={formAddress.loadingPostalCodeInfo.value}
                                errors={(form.getValues('postalCode') !== undefined) && !states.isPostalCodeValid}
                              >
                                Municipio
                              </InputLabel>
                            )}
                          />
                        </div>

                        <div className="claimPerson__content__form__field">
                          <Controller
                            control={form.control}
                            name="state"
                            rules={
                              {
                                required: true,
                              }
                            }
                            render={() => (
                              <InputLabel disabled
                                value={form.getValues('state')}
                                loading={formAddress.loadingPostalCodeInfo.value}
                                errors={(form.getValues('postalCode') !== undefined) && !states.isPostalCodeValid}
                              >
                                Estado
                              </InputLabel>
                            )}
                          />
                        </div>
                      </>
                      :
                      <>
                        {
                          registerInfo && registerInfo.person?.profile?.address &&
                          <>
                            <div className="claimPerson__content__form__field">
                              <Text type="label" align="center" uppercase block>Direcci&oacute;n</Text>
                              <Text type="paragraph" align="center" bold>{registerInfo.person.profile.address.name}</Text>
                            </div>
                            <div className="claimPerson__content__form__field">
                              <Text type="label" align="center" uppercase block>C&oacute;digo postal</Text>
                              <Text type="paragraph" align="center" bold>{registerInfo.person.profile.address.postalCode}</Text>
                            </div>
                            <div className="claimPerson__content__form__field">
                              <Text type="label" align="center" uppercase block>Municipio</Text>
                              <Text type="paragraph" align="center" bold>{registerInfo.person.profile.address.municipality}</Text>
                            </div>
                            <div className="claimPerson__content__form__field">
                              <Text type="label" align="center" uppercase block>Estado</Text>
                              <Text type="paragraph" align="center" bold>{registerInfo.person.profile.address.state}</Text>
                            </div>

                          </>
                        }
                      </>
                  }

                </form>

                <div className="claimRegister__button-wrapper">
                  <Button type="button"
                    disabled={updating.value || (!formAddress.showUserAddress && !form.getValues('postalCode')) || (!formAddress.showUserAddress && !states.isPostalCodeValid)}
                    loading={updating.value}
                    onClick={() => sendCurrentRegisterInfo(false).then(() => changeStep('forward'))}
                  >
                    Siguiente
                  </Button>
                </div>

              </div>
            </ClaimContent>
          }

          {
            pageName === CLAIM_REGISTER_PERSON_PARAMS.LICENSE &&
            <ClaimContent
              title="Necesitamos saber los datos de tu licencia"
            >
              <div className="claimPerson__content">
                <form className="claimPerson__content__form">
                  {
                    !isExternalForm &&
                    <>
                      <div className="claimPerson__content__form__field">
                        <Dropdown
                          placeholder="Selecciona el estado de emisión"
                          defaultValue={form.getValues('licenseState') ? { id: form.getValues('licenseState'), value: form.getValues('licenseState') } : undefined}
                          items={list.countryStates ? list.countryStates.map(s => ({ id: s.state, value: s.state })) : []}
                          onChange={(item) => { form.setValue('licenseState', item.value); form.trigger('licenseState') }}
                        >
                          Estado de emisi&oacute;n
                        </Dropdown>
                      </div>
                      <div className="claimPerson__content__form__field">
                        <Dropdown
                          cancelSearch
                          placeholder="Selecciona el tipo de licencia"
                          defaultValue={states.selectedLicenseType ? { id: states.selectedLicenseType.id, value: states.selectedLicenseType.name } : undefined}
                          onChange={(item) => states.selectLicenceTypeById(item.id as string)}
                          items={list.licenceTypes ? list.licenceTypes.map(l => ({ id: l.id, value: l.name })) : []}
                        >
                          Tipo de licencia
                        </Dropdown>
                      </div>
                    </>
                  }
                  <div className="claimPerson__content__form__field">
                    <Controller
                      control={form.control}
                      name="licenseNumber"
                      rules={
                        {
                          required: true,
                        }
                      }
                      render={({ field: { onChange } }) => (
                        <InputLabel number
                          errors={(!!form.formState.errors.licenseNumber && form.formState.dirtyFields.licenseNumber)}
                          defaultValue={form.getValues('licenseNumber')}
                          onChange={(e) => onChange(e.target.value)}
                        >
                          Nro de licencia
                        </InputLabel>
                      )}
                    />
                  </div>
                  {
                    !isExternalForm &&
                    <>
                      <div className="claimPerson__content__form__field">
                        <Controller
                          control={form.control}
                          name="licenseStartDate"
                          rules={
                            {
                              required: true,
                            }
                          }
                          render={({ field: { onChange } }) => (
                            <InputDate
                              errors={(!!form.formState.errors.licenseStartDate && form.formState.dirtyFields.licenseStartDate)}
                              defaultValue={form.getValues('licenseStartDate') ? form.getValues('licenseStartDate') : ''}
                              minDate={new Date(2000, 0)}
                              onSelectDate={(date) => { onChange(date.toDateString()); form.trigger('licenseStartDate') }}
                              position="top"
                            >
                              Inicio vigencia
                            </InputDate>
                          )}
                        />

                      </div>
                      <div className="claimPerson__content__form__field">
                        <Controller
                          control={form.control}
                          name="licenseEndDate"
                          rules={
                            {
                              required: true,
                            }
                          }
                          render={({ field: { onChange } }) => (
                            <InputDate
                              errors={(!!form.formState.errors.licenseEndDate && form.formState.dirtyFields.licenseEndDate)}
                              defaultValue={form.getValues('licenseEndDate') ? form.getValues('licenseEndDate') : ''}
                              minDate={new Date(2000, 0)}
                              onSelectDate={(date) => { onChange(date.toDateString()); form.trigger('licenseEndDate') }}
                              position="top"
                            >
                              Fin vigencia
                            </InputDate>
                          )}
                        />
                      </div>
                    </>
                  }
                </form>

                <div className="claimRegister__button-wrapper">
                  <Button type="button"
                    disabled={updating.value}
                    loading={updating.value}
                    onClick={() => sendCurrentRegisterInfo(false).then(() => changeStep('forward'))}
                  >
                    Siguiente
                  </Button>
                </div>

              </div>
            </ClaimContent>
          }

          {
            pageName === CLAIM_REGISTER_PERSON_PARAMS.LICENSE_PICTURES &&
            <>
              {
                !formLicense.licenseAlternative ?
                  <ClaimContent
                    title="Ahora necesitamos algunas fotografías"
                    subtitle="Captura imágenes de tu licencia de conducir"
                    key={`content-${formLicense.licenseAlternative}`}
                  >
                    <div className="claimPerson__content">

                      <form className="claimPerson__content__form">

                        <ClaimPicture
                          defaultPicture={licensePictures.pictures[0]}
                          onSelected={(picture) => licensePictures.setPictureValue(0, picture)}
                        />
                        <Text type="paragraph" align="center" block bold>Frente de tu licencia</Text>
                        <ClaimPicture
                          defaultPicture={licensePictures.pictures[1]}
                          onSelected={(picture) => licensePictures.setPictureValue(1, picture)}
                        />
                        <Text type="paragraph" align="center" block bold>Reverso de tu licencia</Text>
                      </form>

                      <div className="claimRegister__button-wrapper">
                        <Button type="button"
                          disabled={updating.value}
                          loading={updating.value}
                          onClick={() => sendCurrentRegisterInfo(false, licensePictures.pictures, 'license').then(() => changeStep('forward'))}
                        >
                          Siguiente
                        </Button>
                        <Text type="label" align="center" block className="claimRegister__button-wrapper__info">
                          No tengo mi licencia.
                          <button type="button" onClick={() => formLicense.alternateLicensePictures()}>&nbsp;Clic aqu&iacute;</button>
                        </Text>
                      </div>
                    </div>
                  </ClaimContent>
                  :
                  <ClaimContent
                    title="Ahora necesitamos algunas fotografías"
                    subtitle="Captura imágenes de tu INE"
                    key={`content-${formLicense.licenseAlternative}`}
                  >
                    <div className="claimPerson__content">

                      <form className="claimPerson__content__form">

                        <ClaimPicture
                          defaultPicture={inePictures.pictures[0]}
                          onSelected={(picture) => inePictures.setPictureValue(0, picture)}
                        />
                        <Text type="paragraph" align="center" block bold>Frente de tu INE</Text>
                        <ClaimPicture
                          defaultPicture={inePictures.pictures[1]}
                          onSelected={(picture) => inePictures.setPictureValue(1, picture)}
                        />
                        <Text type="paragraph" align="center" block bold>Reverso de tu INE</Text>
                      </form>

                      <div className="claimRegister__button-wrapper">
                        <Button type="button"
                          disabled={updating.value}
                          loading={updating.value}
                          onClick={() => sendCurrentRegisterInfo(false, inePictures.pictures, 'ine').then(() => changeStep('forward'))}
                        >
                          Siguiente
                        </Button>
                        <Text type="label" align="center" block className="claimRegister__button-wrapper__info">
                          <button type="button" onClick={() => formLicense.alternateLicensePictures(true)}>Quiero usar mi licencia.</button>
                        </Text>
                      </div>
                    </div>
                  </ClaimContent>
              }

            </>

          }

          {
            pageName === CLAIM_REGISTER_PERSON_PARAMS.DRIVING_PERMISSION &&
            <ClaimContent
              title="Ahora necesitamos algunas fotografías"
              subtitle="Captura imágenes de tu tarjeta de circulación"
            >
              <div className="claimPerson__content">
                <form className="claimPerson__content__form">
                  <ClaimPicture
                    defaultPicture={drivingPermissionPicture.pictures[0]}
                    onSelected={(picture) => drivingPermissionPicture.setPictureValue(0, picture)}
                  />
                  <Text type="paragraph" align="center" block bold>Tarjeta de circulaci&oacute;n</Text>
                </form>

                <div className="claimRegister__button-wrapper">
                  <Button type="button"
                    disabled={updating.value}
                    loading={updating.value}
                    onClick={() => sendCurrentRegisterInfo(false, drivingPermissionPicture.pictures, 'drivingPermission').then(() => changeStep('forward'))}
                  >
                    Siguiente
                  </Button>
                </div>

              </div>
            </ClaimContent>
          }

          {
            pageName === CLAIM_REGISTER_PERSON_PARAMS.VEHICLE_DATA &&
            <ClaimContent
              title="¿Nos puedes ayudar a completar los datos de tu vehículo?"
              subtitle=""
            >
              <div className="claimPerson__content" ref={contentRef!}>
                <form className="claimPerson__content__form">
                  <Dropdown
                    cancelSearch
                    items={list.vehicleTypes ? list.vehicleTypes.map(l => ({ id: l.id, value: l.name })) : []}
                    onChange={(item) => states.selectVehicleTypeById(item.id as string)}
                    disabled={!isExternalForm}
                    defaultValue={states.selectedVehicleType ? { id: states.selectedVehicleType.id, value: states.selectedVehicleType.name } : undefined}
                    placeholder="Selecciona el tipo"
                  >
                    Tipo
                  </Dropdown>

                  <div className="claimPerson__content__form__field">
                    <Controller
                      control={form.control}
                      name="vehicleBrand"
                      rules={
                        {
                          required: true,
                          pattern: Util.PATTERN.ALPHANUMERIC,
                          maxLength: 100
                        }
                      }
                      render={({ field: { onChange } }) => (
                        <InputLabel
                          placeholder="Escribe aquí el marca"
                          maxLength={100}
                          disabled={!isExternalForm}
                          errors={!!form.formState.errors.vehicleBrand && form.formState.dirtyFields.vehicleBrand}
                          defaultValue={form.getValues('vehicleBrand') || registerInfo?.vehicle?.brand?.name}
                          onChange={(e) => onChange(e.target.value)}
                        >
                          Marca
                        </InputLabel>
                      )}
                    />
                  </div>
                  <div className="claimPerson__content__form__field">
                    <Controller
                      control={form.control}
                      name="vehicleModel"
                      rules={
                        {
                          required: true,
                          pattern: Util.PATTERN.VEHICLE_MODEL,
                          maxLength: 100
                        }
                      }
                      render={({ field: { onChange } }) => (
                        <InputLabel
                          placeholder="Escribe aquí el modelo"
                          maxLength={100}
                          disabled={!isExternalForm}
                          errors={!!form.formState.errors.vehicleModel && form.formState.dirtyFields.vehicleModel}
                          defaultValue={form.getValues('vehicleModel') || registerInfo?.vehicle?.model?.name}
                          onChange={(e) => onChange(e.target.value)}
                        >
                          Modelo
                        </InputLabel>
                      )}
                    />
                  </div>
                  <div className="claimPerson__content__form__field">
                    <Controller
                      control={form.control}
                      name="vehicleYear"
                      rules={
                        {
                          required: true,
                          pattern: Util.PATTERN.NUMBERS,
                          maxLength: 4
                        }
                      }
                      render={({ field: { onChange } }) => (
                        <InputLabel
                          number
                          placeholder="Escribe aquí el año"
                          maxLength={4}
                          disabled={!isExternalForm}
                          errors={!!form.formState.errors.vehicleYear && form.formState.dirtyFields.vehicleYear}
                          defaultValue={+form.getValues('vehicleYear') > 0 ? form.getValues('vehicleYear') : undefined}
                          onChange={(e) => onChange(e.target.value)}
                        >
                          A&ntilde;o
                        </InputLabel>
                      )}
                    />
                  </div>
                  <div className="claimPerson__content__form__field">
                    <Controller
                      control={form.control}
                      name="plates"
                      rules={
                        {
                          required: true,
                          pattern: Util.PATTERN.VEHICLE_PLATE,
                          maxLength: 20
                        }
                      }
                      render={({ field: { onChange } }) => (
                        <InputLabel
                          placeholder="Escribe aquí las placas"
                          maxLength={7}
                          errors={!!form.formState.errors.plates && form.formState.dirtyFields.plates}
                          defaultValue={form.getValues('plates') || registerInfo?.vehicle?.plate}
                          onChange={(e) => onChange(e.target.value)}
                        >
                          Placas
                        </InputLabel>
                      )}
                    />
                  </div>

                  <div className="claimPerson__content__form__field">
                    <Dropdown
                      cancelSearch
                      items={list.colors ? list.colors.map(l => ({ id: l.id, value: l.name })) : []}
                      onChange={(item) => states.selectColorById(item.id as string)}
                      defaultValue={states.selectedColor ? { id: states.selectedColor.id, value: states.selectedColor.name } : undefined}
                      placeholder="Selecciona el color del vehículo"
                      openOverflow
                      onDropdownOpen={() => {
                        if (contentRef && contentRef.current)
                          contentRef.current.scrollIntoView({ behavior: 'smooth' })
                      }}
                    >
                      Color
                    </Dropdown>
                  </div>
                </form>

                <div className="claimRegister__button-wrapper">
                  <Button type="button"
                    disabled={updating.value || !states.selectedVehicleType}
                    loading={updating.value}
                    onClick={() => sendCurrentRegisterInfo(false).then(() => changeStep('forward'))}
                  >
                    Siguiente
                  </Button>
                </div>

              </div>
            </ClaimContent>
          }

          {
            pageName === CLAIM_REGISTER_PERSON_PARAMS.VEHICLE_VIN &&
            <ClaimContent
              title="¿Podrías tomar una foto del número de serie del vehículo?"
            >
              <div className="claimPerson__content">
                <form className="claimPerson__content__form">
                  <div className="claimPerson__content__form__field">
                    <ClaimPicture
                      defaultPicture={vinPictures.pictures[0]}
                      onSelected={(picture) => vinPictures.setPictureValue(0, picture)}
                    />
                    <Text type="paragraph" align="center" block bold>Foto del n&uacute;mero de serie</Text>
                  </div>
                  <div className="claimPerson__content__form__field">
                    <Controller
                      control={form.control}
                      name="vin"
                      rules={
                        {
                          required: true,
                          pattern: Util.PATTERN.VEHICLE_VIN,
                          maxLength: 17
                        }
                      }
                      render={({ field: { onChange } }) => (
                        <InputLabel
                          disabled={!isExternalForm}
                          placeholder="Escribe aquí el número de serie"
                          maxLength={17}
                          errors={!!form.formState.errors.vin && form.formState.dirtyFields.vin}
                          defaultValue={form.getValues('vin')}
                          onChange={(e) => onChange(e.target.value)}
                        >
                          N&uacute;mero de serie
                        </InputLabel>
                      )}
                    />
                  </div>

                  <div className="claimPerson__content__form__info">
                    <div className="claimPerson__content__form__info__right">
                      <Text type="label">
                        <b>¿No sabes d&oacute;nde está el n&uacute;mero de serie?</b> <br/>Lo puedes encontrar en el tablero, en la puerta del conductor, o en la pared de fuego.
                      </Text>
                    </div>
                  </div>

                </form>

                <div className="claimRegister__button-wrapper">
                  <Button type="button"
                    disabled={updating.value}
                    loading={updating.value}
                    onClick={() => sendCurrentRegisterInfo(false, vinPictures.pictures, 'vin').then(() => changeStep('forward'))}
                  >
                    Siguiente
                  </Button>
                </div>

              </div>
            </ClaimContent>
          }

          {
            pageName === CLAIM_REGISTER_PERSON_PARAMS.VEHICLE_PICTURES &&
            <ClaimContent
              title="Necesitamos algunas fotos del vehículo"
              subtitle="Captura imágenes generales del auto"
            >
              <div className="claimPerson__content">
                <div className="claimPerson__content__wrapper claimPerson__content__wrapper--wrap">
                  <div className="claimPerson__content__wrapper__field">
                    <ClaimPicture
                      defaultPicture={vehiclePictures.pictures[0]}
                      onSelected={(picture) => vehiclePictures.setPictureValue(0, picture)}
                    />
                    <Text type="paragraph" align="center" block>Foto <br /><b>frontal</b></Text>
                  </div>
                  <div className="claimPerson__content__wrapper__field">
                    <ClaimPicture
                      defaultPicture={vehiclePictures.pictures[1]}
                      onSelected={(picture) => vehiclePictures.setPictureValue(1, picture)}
                    />
                    <Text type="paragraph" align="center" block>Foto <br /><b>trasera</b></Text>
                  </div>
                  <div className="claimPerson__content__wrapper__field">
                    <ClaimPicture
                      defaultPicture={vehiclePictures.pictures[2]}
                      onSelected={(picture) => vehiclePictures.setPictureValue(2, picture)}
                    />
                    <Text type="paragraph" align="center" block>Foto lado <br /><b>izquierdo</b></Text>
                  </div>
                  <div className="claimPerson__content__wrapper__field">
                    <ClaimPicture
                      defaultPicture={vehiclePictures.pictures[3]}
                      onSelected={(picture) => vehiclePictures.setPictureValue(3, picture)}
                    />
                    <Text type="paragraph" align="center" block>Foto lado <br /><b>derecho</b></Text>
                  </div>

                </div>

                <div className="claimRegister__button-wrapper">
                  <Button type="button"
                    disabled={updating.value}
                    loading={updating.value}
                    onClick={() => sendCurrentRegisterInfo(false, vehiclePictures.pictures, 'vehicle').then(() => changeStep('forward'))}
                  >
                    Siguiente
                  </Button>
                </div>

              </div>
            </ClaimContent>
          }

          {
            pageName === CLAIM_REGISTER_PERSON_PARAMS.DAMAGE &&
            <>
              {
                !showDamage ?
                  <ClaimContent
                    title="Por último añade uno a uno cada daño"
                    subtitle="Toma fotos de todos los daños de tu auto"
                  >
                    <div className="claimPerson__content">
                      <div className="claimPerson__content__wrapper">

                        {
                          states.selectedDamages.map(
                            (damage, index) => (
                              <div className="claimPerson__content__wrapper__damage" key={`damage-${index}`}>
                                <span className="claimPerson__content__wrapper__damage__image" style={{ backgroundImage: `url(${Util.ASSET.COVERAGE_DT})` }}></span>
                                <div className="claimPerson__content__wrapper__damage__type">
                                  <Text type="label" uppercase>&Aacute;rea del da&ntilde;o</Text>
                                  <Text type="paragraph" bold>{damage.type.name}</Text>
                                </div>
                                <div className="claimPerson__content__wrapper__damage__buttons">
                                  <button type="button" className="claimPerson__content__wrapper__damage__buttons__edit"
                                    onClick={() => { formDamages.setDamageEdition(damage, index); setShowDamage(true) }}
                                  >
                                    {IconFactory.editIcon()}
                                  </button>
                                  <button type="button" className="claimPerson__content__wrapper__damage__buttons__remove"
                                    onClick={() => formDamages.removeDamage(index)}
                                  >
                                    {IconFactory.removeIcon()}
                                  </button>
                                </div>
                              </div>
                            )
                          )
                        }


                        <div className="claimPerson__content__wrapper__add-button">
                          <button type="button"
                            onClick={() => { formDamages.setDamageEdition(); setShowDamage(true) }}
                          >
                            {IconFactory.addIcon()}
                            <Text type="paragraph" align="center" uppercase>
                              A&ntilde;adir
                              {states.selectedDamages.length > 0 ? <>&nbsp;otro&nbsp;</> : <>&nbsp;</>}
                              da&ntilde;o
                            </Text>
                          </button>
                        </div>

                      </div>

                      <div className="claimRegister__button-wrapper">
                        <Button type="button"
                          disabled={updating.value}
                          loading={updating.value}
                          onClick={() => sendCurrentRegisterInfo(false).then(() => changeStep('forward'))}
                        >
                          Siguiente
                        </Button>
                      </div>
                    </div>
                  </ClaimContent>
                  :
                  <ClaimContent
                    //title="Añade todas las fotos de las piezas dañadas"
                    title={`Daño nro. ${formDamages.damageIndexToEdit !== undefined ? (formDamages.damageIndexToEdit + 1) : (states.selectedDamages.length + 1) }`}
                    subtitle="Añade fotos de la pieza dañada"
                  >
                    <div className="claimPerson__content">
                      <div className="claimPerson__content__wrapper">
                        <ClaimDamage
                          selectedDamage={formDamages.damageToEdit}
                          selectedIndex={formDamages.damageIndexToEdit}
                          formType='people'
                          onSelect={(damage) => { formDamages.addDamage(damage); setShowDamage(false) }}
                          onEdit={(damage) => { formDamages.editDamage(damage); setShowDamage(false) }}
                          damageType={states.selectedVehicleType}
                        />
                      </div>
                    </div>
                  </ClaimContent>
              }

            </>
          }

          {
            pageName === CLAIM_REGISTER_PERSON_PARAMS.SUMMARY &&
            <ClaimContent
              title="Resumen de datos"
            >
              <div className="claimPerson__content">
                <div className="claimPerson__content__wrapper">
                  <ClaimSummary
                    form={form}
                    address={formAddress.currentAddress}
                    licenceType={states.selectedLicenseType}
                    vehicleColor={states.selectedColor}
                    damages={states.selectedDamages}
                    inePictures={inePictures.pictures}
                    drivingPermissionPicture={drivingPermissionPicture.pictures}
                    licensePictures={licensePictures.pictures}
                    referencePictures={referencePictures.pictures}
                    vehiclePictures={vehiclePictures.pictures}
                    vinPictures={vinPictures.pictures}
                    licenseAlternative={formLicense.licenseAlternative}
                    vehicle={registerInfo?.vehicle}
                    vehicleType={states.selectedVehicleType}
                  />
                </div>

                <div className="claimRegister__button-wrapper">
                  <Button type="button" onClick={() => changeStep('forward')}>Siguiente</Button>
                </div>
              </div>
            </ClaimContent>
          }

          {
            pageName === CLAIM_REGISTER_PERSON_PARAMS.SIGNATURE &&
            <ClaimContent
              title="Firma digital de aceptación de tus respuestas"
            >
              <div className="claimPerson__content">
                <div className="claimPerson__content__wrapper">
                  <Controller
                    control={form.control}
                    name="signature"
                    rules={{
                      required: true
                    }}
                    render={({ field: { onChange } }) => (
                      <ClaimSignature
                        label="Firma del conductor"
                        onSign={(signature) => onChange(signature!)}
                        personFullName={`${form.getValues('name')} ${form.getValues('lastName')} ${form.getValues('secondLastName')}`}
                      />
                    )}
                  />
                </div>

                <div className="claimRegister__button-wrapper">
                  <Button type="button"
                    disabled={!form.formState.isValid || updating.value}
                    loading={updating.value}
                    onClick={() => sendCurrentRegisterInfo(true).then(() => changeStep('forward'))}
                  >
                    Siguiente</Button>
                </div>
              </div>
            </ClaimContent>
          }

          {
            pageName === CLAIM_REGISTER_PERSON_PARAMS.COMPLETED &&
            <ClaimContent
              title=""
            >
              <div className="claimPerson__content">
                <div className="claimRegister__completion">
                  <div className="claimRegister__completion__logo">
                    <Logo type="full" />
                  </div>
                  <Text type="paragraph" align="center">Muchas gracias, <b>{registerInfo?.person.name}</b></Text>
                  <Text type="title" align="center" className="claimRegister__completion__title">
                    Pronto ser&aacute;s contactado <br /> por cabina
                  </Text>
                  <Text type="paragraph" align="center">Si necesitas ayuda adicional <br /> puedes contactar a cabina de nuevo.</Text>

                  <Text type="paragraph" align="center" bold className="claimRegister__completion__link">Ir a <a href="https://crabi.com" target="_self" >crabi.com</a></Text>
                </div>
              </div>
            </ClaimContent>
          }
        </>
      }
    </div>
  );
};

export default ClaimPersonPage;