import { Address } from "../Address";
import { Person } from "../Person";
import { Phone } from "../Phone";
import { Vehicle } from "../vehicle/Vehicle";
import { ClaimRegisterPicture } from "./ClaimRegisterPicture";
import { Damage } from "./Damage";
import { License } from "./License";
import { Service } from "./Service";
import { StatusTypeItem } from "./StatusTypeItem";
import { Provider } from "./Provider";
import { Profile } from "../Profile";
import { VehicleBrand } from "../vehicle/VehicleBrand";
import { VehicleModel } from "../vehicle/VehicleModel";
import { VehicleVersion } from "../vehicle/VehicleVersion";
import { VehicleYear } from "../vehicle/VehicleYear";

export class ClaimRegister {

  /**
   *
   */
  private _formId: string;
  set formId(_formId: string) { this._formId = _formId };
  get formId(): string { return this._formId };

  /**
   *
   */
  private _claimId: string;
  set claimId(_claimId: string) { this._claimId = _claimId };
  get claimId(): string { return this._claimId };
  /**
   *
   */
  private _claimNumber: string;
  set claimNumber(_claimNumber: string) { this._claimNumber = _claimNumber };
  get claimNumber(): string { return this._claimNumber };

  /**
   *
   */
  private _claimType: StatusTypeItem | undefined;
  set claimType(_claimType: StatusTypeItem | undefined) { this._claimType = _claimType };
  get claimType(): StatusTypeItem | undefined { return this._claimType };

  /**
   *
   */
  private _personType: StatusTypeItem;
  set personType(_personType: StatusTypeItem) { this._personType = _personType };
  get personType(): StatusTypeItem { return this._personType };

  /**
   *
   */
  private _facts: string | undefined;
  set facts(_facts: string | undefined) { this._facts = _facts };
  get facts(): string | undefined { return this._facts };

  /**
   * 
   */
  private _folId: string | undefined;
  set folId(_folId: string | undefined) { this._folId = _folId };
  get folId(): string | undefined { return this._folId };

  /**
   *
   */
  private _person: Person;
  set person(_person: Person) { this._person = _person };
  get person(): Person { return this._person };

  /**
   *
   */
  private _license: License | undefined;
  set license(_license: License | undefined) { this._license = _license };
  get license(): License | undefined { return this._license };

  /**
   *
   */
  private _vehicle: Vehicle | undefined;
  set vehicle(_vehicle: Vehicle | undefined) { this._vehicle = _vehicle };
  get vehicle(): Vehicle | undefined { return this._vehicle };

  /**
   *
   */
  private _vehicleColor: StatusTypeItem | undefined;
  set vehicleColor(_vehicleColor: StatusTypeItem | undefined) { this._vehicleColor = _vehicleColor };
  get vehicleColor(): StatusTypeItem | undefined { return this._vehicleColor };

  /**
   *
   */
  private _vehicleType: StatusTypeItem | undefined;
  set vehicleType(_vehicleType: StatusTypeItem | undefined) { this._vehicleType = _vehicleType };
  get vehicleType(): StatusTypeItem | undefined { return this._vehicleType };

  /**
   * 
   */
  private _circumstance: StatusTypeItem | undefined;
  set circumstance(_circumstance: StatusTypeItem | undefined) { this._circumstance = _circumstance };
  get circumstance(): StatusTypeItem | undefined { return this._circumstance };

  /**
   *
   */
  private _damages: Damage[];
  set damages(_damages: Damage[]) { this._damages = _damages };
  get damages(): Damage[] { return this._damages };

	/** 
	 * 
	 */
	private _services: Service[];
	set services(_services: Service[]) { this._services = _services };
	get services(): Service[] { return this._services };

  /**
   *
   */
  private _completed: boolean;
  set completed(_completed: boolean) { this._completed = _completed };
  get completed(): boolean { return this._completed };

  /**
   *
   */
  private _referencePictures: ClaimRegisterPicture[];
  set referencePictures(_referencePictures: ClaimRegisterPicture[]) { this._referencePictures = _referencePictures };
  get referencePictures(): ClaimRegisterPicture[] { return this._referencePictures };

  /**
   *
   */
  private _licensePictures: ClaimRegisterPicture[];
  set licensePictures(_licensePictures: ClaimRegisterPicture[]) { this._licensePictures = _licensePictures };
  get licensePictures(): ClaimRegisterPicture[] { return this._licensePictures };

  /**
   *
   */
  private _inePictures: ClaimRegisterPicture[];
  set inePictures(_inePictures: ClaimRegisterPicture[]) { this._inePictures = _inePictures };
  get inePictures(): ClaimRegisterPicture[] { return this._inePictures };

  /**
   *
   */
  private _drivingPermissionPictures: ClaimRegisterPicture[];
  set drivingPermissionPictures(_drivingPermissionPictures: ClaimRegisterPicture[]) { this._drivingPermissionPictures = _drivingPermissionPictures };
  get drivingPermissionPictures(): ClaimRegisterPicture[] { return this._drivingPermissionPictures };

  /**
   *
   */
  private _vinPictures: ClaimRegisterPicture[];
  set vinPictures(_vinPictures: ClaimRegisterPicture[]) { this._vinPictures = _vinPictures };
  get vinPictures(): ClaimRegisterPicture[] { return this._vinPictures };

  /**
   *
   */
  private _vehiclePictures: ClaimRegisterPicture[];
  set vehiclePictures(_vehiclePictures: ClaimRegisterPicture[]) { this._vehiclePictures = _vehiclePictures };
  get vehiclePictures(): ClaimRegisterPicture[] { return this._vehiclePictures };

  /**
   *
   */
  private _signature: string | undefined;
  set signature(_signature: string | undefined) { this._signature = _signature };
  get signature(): string | undefined { return this._signature };

	/**
	 * 
	 */
	private _insuranceCompany: Provider | undefined;
	set insuranceCompany(_insuranceCompany: Provider | undefined) { this._insuranceCompany = _insuranceCompany };
	get insuranceCompany(): Provider | undefined { return this._insuranceCompany };

	/**
	 * 
	 */
	private _policyNumber: string;
	set policyNumber(_policyNumber: string) { this._policyNumber = _policyNumber };
	get policyNumber(): string { return this._policyNumber };

	/**
	 * 
	 */
	private _issueNumber: string;
	set issueNumber(_issueNumber: string) { this._issueNumber = _issueNumber };
	get issueNumber(): string { return this._issueNumber };

	/**
	 * 
	 */
	private _thirdInsuranceClaimNumber: string;
	set thirdInsuranceClaimNumber(_thirdInsuranceClaimNumber: string) { this._thirdInsuranceClaimNumber = _thirdInsuranceClaimNumber };
	get thirdInsuranceClaimNumber(): string { return this._thirdInsuranceClaimNumber };

	/** 
	 * 
	 */
	private _thirdInsuranceDuaNumber: string;
	set thirdInsuranceDuaNumber(_thirdInsuranceDuaNumber: string) { this._thirdInsuranceDuaNumber = _thirdInsuranceDuaNumber };
	get thirdInsuranceDuaNumber(): string { return this._thirdInsuranceDuaNumber };

	/**
	 * 
	 */
	private _thirdInsuranceAdjusterName: string;
	set thirdInsuranceAdjusterName(_thirdInsuranceAdjusterName: string) { this._thirdInsuranceAdjusterName = _thirdInsuranceAdjusterName };
	get thirdInsuranceAdjusterName(): string { return this._thirdInsuranceAdjusterName };

  /**
   * 
   */
  private _isPrior: boolean;
  set isPrior(_isPrior: boolean) { this._isPrior = _isPrior };
  get isPrior(): boolean { return this._isPrior };

  constructor(_formId: string, _claimId: string, _personType: StatusTypeItem) {
    this._formId = _formId;
    this._claimId = _claimId;
    this._personType = _personType;
    this._claimNumber = '';
    this._completed = false;
    this._damages = [];
		this._services = [];
    this._referencePictures = [];
    this._licensePictures = [];
    this._inePictures = [];
    this._drivingPermissionPictures = [];
    this._vinPictures = [];
    this._vehiclePictures = [];
		this._policyNumber = '';
		this._issueNumber = '';
		this._thirdInsuranceClaimNumber = '';
		this._thirdInsuranceDuaNumber = '';
		this._thirdInsuranceAdjusterName = '';
    this._isPrior = false;
    this._person = new Person();
  }

  /**
   * 
   * @param _name 
   * @param _lastName 
   * @param _secondLastName 
   * @param _email 
   */
  public setPersonValues(_name?: string, _lastName?: string, _secondLastName?: string, _email?: string): void {
		this._person.name = _name!;
		this._person.lastName = _lastName!;
		this._person.secondLastName = _secondLastName!;
		this._person.email = _email!;
  }

  /**
   * 
   * @param _phoneNumber 
   */
  public setProfileValues(_countryCodeValue: string, _phoneNumber?: string, _birthdate?: string, _gender?: number): void {
		if (!this._person.profile)
			this._person.profile = new Profile();

    if (this._person && _phoneNumber) {
      this._person.profile.phone = new Phone();
      this._person.profile.phone.setCountryCodeByFullNumber(`${_countryCodeValue}${_phoneNumber}`);

      if (_birthdate) {
        this._person.profile.birthdate = new Date(_birthdate);
      }

			if (_gender) {
				this._person.profile.gender = _gender;
			}
    }
  }

  /**
   * 
   * @param _postalCode 
   * @param _name 
   * @param municipality 
   * @param state 
   */
  public setAddressValues(_postalCode?: string, _name?: string, _municipality?: string, _state?: string, _latitude?: number, _longitude?: number): void {
		if (!this._person.profile)
			this._person.profile = new Profile();

    if (_postalCode) {
      this._person.profile.address = new Address(_postalCode);
      if (_name) {
        this._person.profile.address.name = _name;
      }

      if (_municipality) {
        this._person.profile.address.municipality = _municipality;
      }

      if (_state) {
        this._person.profile.address.state = _state;
      }

      if (_latitude) {
        this._person.profile.address.latitude = _latitude;
      }

      if (_longitude) {
        this._person.profile.address.longitude = _longitude;
      }
    }
  }

  /**
   * 
   * @param _number 
   * @param _type 
   * @param _stateName 
   * @param _startDate 
   * @param _endDate 
   */
  public setLicenceValues(_number?: string, _type?: StatusTypeItem, _stateName?: string, _startDate?: string, _endDate?: string): void {
		if (!this._license)
			this._license = new License();

		this._license.number = _number!;
		this._license.type = _type!;
		this._license.stateName = _stateName!;
		if (_startDate && _endDate) {
			this._license.startDate = new Date(_startDate);
			this._license.endDate = new Date(_endDate);
		}
  }

  /**
   * 
   */
  public deletePolicyValues(): void {
    this._insuranceCompany = undefined;
    this._policyNumber = '';
    this._issueNumber = '';
    this._thirdInsuranceAdjusterName = '';
    this._thirdInsuranceClaimNumber = '';
    this._thirdInsuranceDuaNumber = '';
  }

	/**
	 * 
	 * @param _insuranceCompany 
	 * @param _policyNumber 
	 * @param _issueNumber 
	 * @param _claimNumber 
	 */
	public setPolicyValues(_insuranceCompanyId: string, _insuranceCompany: string, _policyNumber: string, _issueNumber: string, _claimNumber: string, _duaNumber: string, _adjusterName: string): void {
		this._insuranceCompany = new Provider();
		this._insuranceCompany.id = _insuranceCompanyId;
		this._insuranceCompany.name = _insuranceCompany;
		this._insuranceCompany.isSubProvider = false;
		this._policyNumber = _policyNumber;
		this._issueNumber = _issueNumber;
		this._thirdInsuranceClaimNumber = _claimNumber;
		this._thirdInsuranceDuaNumber = _duaNumber;
		this._thirdInsuranceAdjusterName = _adjusterName;
	}

  /**
   * 
   * @param _brandName 
   * @param _modelName 
   * @param _year 
   * @param _vin 
   * @param _color 
   * @param _plate 
   */
  public setVehicleValues(_brandName?: string, _modelName?: string, _year?: string, _vin?: string, _color?: StatusTypeItem, _plate?: string, _vehicleType?: StatusTypeItem, _comercialVaue?: number): void {
		if (!this._vehicle) {
			this._vehicle = new Vehicle();
			this._vehicle.brand = new VehicleBrand();
			this._vehicle.model = new VehicleModel();
			this._vehicle.version = new VehicleVersion();
			this._vehicle.year = new VehicleYear();
		}

    if (_vehicleType)
			this._vehicleType = _vehicleType;

		if (this._vehicle.brand)
			this._vehicle.brand.name = _brandName ? _brandName : '';
	
		if (this._vehicle.model)
			this._vehicle.model.name = _modelName ? _modelName : '';

		if (this._vehicle.year)
			this._vehicle.year.value = _year ? parseInt(_year) : -1;

    if (_comercialVaue)
      this._vehicle.comercialValue = _comercialVaue;

		this._vehicle.plate = _plate ? _plate.toLocaleUpperCase() : '';
    this._vehicle.vin = _vin ? _vin.toLocaleUpperCase() : '';
		this._vehicleColor = _color;
  }

	/**
	 * 
	 * @param _newServices 
	 */
	public setServiceValues(
		_serviceType: StatusTypeItem, 
		_serviceCategory?: StatusTypeItem, 
		_provider?: Provider, 
		_isSubProvider?: boolean,
		_coverage?: StatusTypeItem, 
		_amount?: string, 
		_sheetNumber?: string,
		_hasDeductible?: boolean,
		_operatorName?: string,
		_operatorCountryCode?: string,
		_operatorPhone?: string,
		_description?: string,
    _pictures?: ClaimRegisterPicture[],
		_index?: number
	): void {
		let _newList = [...this._services];

		let service = _index !== undefined ? _newList[_index] : new Service();

		service.type = _serviceType;

		if(_serviceCategory)
			service.category = _serviceCategory;

		if (_provider) {
			service.provider = new Provider();
			service.provider.id = _provider.id;
			service.provider.name = _provider.name;

			if (_isSubProvider !== undefined)
				service.provider.isSubProvider = _isSubProvider;
		}

		if (_coverage)
			service.coverage = _coverage;

		if (_amount !== undefined)
			service.amount = parseInt(_amount);

		if (_sheetNumber)
			service.itemNumber = _sheetNumber;

		if (_operatorName)
			service.operatorName = _operatorName;

		if (_hasDeductible !== undefined)
			service.hasDeductible = _hasDeductible;

		if (_operatorCountryCode && _operatorPhone) {
			service.operatorPhone = new Phone(_operatorPhone);
			service.operatorPhone.countryCode = {
				code: _operatorCountryCode
			}
		}

		if (_description)
			service.description = _description || '';

		if (_index !== undefined && _newList.length > _index) {
			_newList[_index] = service;
		} else {
			_newList.push(service);
		}

    if (_pictures)
      service.pictures = _pictures;

		this._services = _newList;
	}

  /**
   * 
   * @param _newDamages 
   */
  public setDamages(_newDamages: Damage[]): void {
    let _newList: Damage[] = [];
    for (const dmg of _newDamages) {
      _newList.push(dmg);
    }

    this._damages = _newList;
  }

	/**
	 * 
	 * @returns 
	 */
	public checkVehicle(): boolean {
		const checkValue = this._vehicle !== undefined 
			&& this._vehicle.brand !== undefined && this._vehicle.brand.name.length > 0 
			&& this._vehicle.model !== undefined && this._vehicle.model.name.length > 0
			&& this._vehicle.year !== undefined && this._vehicle.year.value > 0
			&& this._vehicle.vin !== null && this._vehicle.vin.length > 0
			&& this._vehicle.plate !== null && this._vehicle.plate.length > 0
			&& this._vehicle.color.length > 0

		return checkValue;
	}
}