import { Util } from "src/utils/Util";
import { Address } from "../Address";
import { Phone } from "../Phone";
import { ClaimRegister } from "./ClaimRegister";
import { StatusTypeItem } from "./StatusTypeItem";
import { ClaimRegisterPicture } from "./ClaimRegisterPicture";
import { Coverage } from "../plan/Coverage";
import { Policy } from "../Policy";

export class ClaimAdjuster {

  /**
   * 
   */
  private _name: string;
  set name(_name: string) { this._name = _name };
  get name(): string { return this._name };

  /**
   * 
   */
  private _signature: string | undefined;
  set signature(_signature: string | undefined) { this._signature = _signature };
  get signature(): string | undefined { return this._signature };

  /**
   * 
   */
  private _email: string;
  set email(_email: string) { this._email = _email };
  get email(): string { return this._email };

  /**
   *
   */
  private _formId: string;
  set formId(_formId: string) { this._formId = _formId };
  get formId(): string { return this._formId };

  /**
   *
   */
  private _claimId: string;
  set claimId(_claimId: string) { this._claimId = _claimId };
  get claimId(): string { return this._claimId };

  /**
   *
   */
  private _claimNumber: string;
  set claimNumber(_claimNumber: string) { this._claimNumber = _claimNumber };
  get claimNumber(): string { return this._claimNumber };

  /**
   *
   */
  private _reportingName: string;
  set reportingName(_reportingName: string) { this._reportingName = _reportingName };
  get reportingName(): string { return this._reportingName };

  /**
   *
   */
  private _reportingPhone: Phone | undefined;
  set reportingPhone(_reportingPhone: Phone | undefined) { this._reportingPhone = _reportingPhone };
  get reportingPhone(): Phone | undefined { return this._reportingPhone };

  /**
   *
   */
  private _vehicleInvolved: string | undefined;
  set vehicleInvolved(_vehicleInvolved: string | undefined) { this._vehicleInvolved = _vehicleInvolved };
  get vehicleInvolved(): string | undefined { return this._vehicleInvolved };

  /**
   *
   */
  private _location: Address | undefined;
  set location(_location: Address | undefined) { this._location = _location };
  get location(): Address | undefined { return this._location };

  /**
   *
   */
  private _cabinFacts: string;
  set cabinFacts(_cabinFacts: string) { this._cabinFacts = _cabinFacts };
  get cabinFacts(): string { return this._cabinFacts };

  /**
   *
   */
  private _peopleFacts: string;
  set peopleFacts(_peopleFacts: string) { this._peopleFacts = _peopleFacts };
  get peopleFacts(): string { return this._peopleFacts };

  /**
   *
   */
  private _clousure: string;
  set clousure(_clousure: string) { this._clousure = _clousure };
  get clousure(): string { return this._clousure };

  /**
   *
   */
  private _date: Date | undefined;
  set date(_date: Date | undefined) { this._date = _date };
  get date(): Date | undefined { return this._date };

  /**
   *
   */
  private _completed: boolean;
  set completed(_completed: boolean) { this._completed = _completed };
  get completed(): boolean { return this._completed };

  /** 
   * 
   */
  private _isFirstForm: boolean;
  set isFirstForm(_isFirstForm: boolean) { this._isFirstForm = _isFirstForm };
  get isFirstForm(): boolean { return this._isFirstForm };

  /**
   *
   */
  private _claimPeople: ClaimRegister[];
  set claimPeople(_claimPeople: ClaimRegister[]) { this._claimPeople = _claimPeople };
  get claimPeople(): ClaimRegister[] { return this._claimPeople };

	/**
	 * 
	 */
	private _claimType: StatusTypeItem;
	set claimType(_claimType: StatusTypeItem) { this._claimType = _claimType };
	get claimType(): StatusTypeItem { return this._claimType };

  /**
   *
   */
  private _cause: StatusTypeItem | undefined;
  set cause(_cause: StatusTypeItem | undefined) { this._cause = _cause };
  get cause(): StatusTypeItem | undefined { return this._cause };

  /**
   * 
   */
  private _circumstance: StatusTypeItem | undefined;
  set circumstance(_circumstance: StatusTypeItem | undefined) { this._circumstance = _circumstance };
  get circumstance(): StatusTypeItem | undefined { return this._circumstance };

  /**
   *
   */
  private _liability: StatusTypeItem | undefined;
  set liability(_liability: StatusTypeItem | undefined) { this._liability = _liability };
  get liability(): StatusTypeItem | undefined { return this._liability };

  /**
   *
   */
  private _reason: StatusTypeItem | undefined;
  set reason(_reason: StatusTypeItem | undefined) { this._reason = _reason };
  get reason(): StatusTypeItem | undefined { return this._reason };

  /**
   *
   */
  private _referencePictures: ClaimRegisterPicture[];
  set referencePictures(_referencePictures: ClaimRegisterPicture[]) { this._referencePictures = _referencePictures };
  get referencePictures(): ClaimRegisterPicture[] { return this._referencePictures };

  /**
   *
   */
  private _duaPicture: ClaimRegisterPicture[];
  set duaPicture(_duaPicture: ClaimRegisterPicture[]) { this._duaPicture = _duaPicture };
  get duaPicture(): ClaimRegisterPicture[] { return this._duaPicture };

  /**
   *
   */
  private _coverages: Coverage[];
  set coverages(_coverages: Coverage[]) { this._coverages = _coverages };
  get coverages(): Coverage[] { return this._coverages };

  /**
   *
   */
  private _declarationSignature: string | undefined;
  set declarationSignature(_declarationSignature: string | undefined) { this._declarationSignature = _declarationSignature };
  get declarationSignature(): string | undefined { return this._declarationSignature };

  /**
   *
   */
  private _policy: Policy | undefined;
  set policy(_policy: Policy | undefined) { this._policy = _policy };
  get policy(): Policy | undefined { return this._policy };

  /**
   * 
   */
  private _isFormStarted: boolean;
  set isFormStarted(_isFormStarted: boolean) { this._isFormStarted = _isFormStarted };
  get isFormStarted(): boolean { return this._isFormStarted };

  /** Class attribute description. */
  private _isInvolvedsCompleted: boolean;
  set isInvolvedsCompleted(_isInvolvedsCompleted: boolean) { this._isInvolvedsCompleted = _isInvolvedsCompleted };
  get isInvolvedsCompleted(): boolean { return this._isInvolvedsCompleted };

  /**
   * 
   */
  private _formNotices: string[] | undefined;
  set formNotices(_formNotices: string[] | undefined) { this._formNotices = _formNotices };
  get formNotices(): string[] | undefined { return this._formNotices };

  constructor(_name: string, _email: string, _formId: string, _claimId: string, _claimNumber: string, _reportingName: string, _cabinFacts: string, _type: StatusTypeItem) {
    this._name = _name;
    this._email = _email;
    this._reportingName = _reportingName;
    this._cabinFacts = _cabinFacts;
    this._formId = _formId;
    this._isFirstForm = false;
    this._claimId = _claimId;
    this._claimNumber = _claimNumber;
    this._claimType = _type;
    this._completed = false;
    this._claimPeople = [];
    
    this._peopleFacts = '';
    this._clousure = '';
    this._referencePictures = [];
    this._duaPicture = [];
    this._coverages = [];
    this._isFormStarted = false;
    this._isInvolvedsCompleted = false;
  }

  /**
   * 
   */
  public setDeclarations(_peopleFacts?: string, _cause?: StatusTypeItem, _circumstance?: StatusTypeItem, _liability?: StatusTypeItem, _signature?: string): void {
    if (_peopleFacts) {
      this._peopleFacts = _peopleFacts;
    }

    if (_cause) {
      this._cause = _cause;
    }

    if (_circumstance) {
      this._circumstance = _circumstance;

      if (this._claimPeople[0])
        this._claimPeople[0].circumstance = _circumstance;
    }

    if (_liability) {
      this._liability = _liability;
    }

    if (_signature) {
      this._declarationSignature = _signature;
    }
  }

	/**
	 * 
	 */
	public updateClaimPerson(claimPerson: ClaimRegister, index?: number): void {
		if (this._claimPeople.length > 0) {
			if (claimPerson.person?.id) {
				for(let p of this._claimPeople) {
					if (p.person && p.person.id === claimPerson.person.id) {
						p = claimPerson;
						break;
					}
				}
			} else if (!!index) {
				this._claimPeople[index] = claimPerson;
			} else {
        this._claimPeople[this._claimPeople.length - 1] = claimPerson;
      }
		}
	};

	/**
	 * 
	 */
	public deleteClaimPerson(index: number): void {
		if (this._claimPeople[index].personType.id !== Util.STATUS_CODE.CLAIMS_PERSON_TYPES.INSURED)
			this._claimPeople.splice(index, 1);
	}

	/**
	 * 
	 * @param claimPerson 
	 * @returns 
	 */
	public addClaimPeople(claimPerson: ClaimRegister): void {
		if (!claimPerson.claimId) {
			claimPerson.claimId = this._claimPeople[0].claimId;
			claimPerson.formId = this._claimPeople[0].formId;
		}

		this._claimPeople.push(claimPerson);
	}

  /**
   * 
   */
  public setClousure(_clousure?: string, _reason?: StatusTypeItem): void {
    if (_clousure)
      this._clousure = _clousure;

    if (_reason)
      this._reason = _reason;
    else 
      this._reason = undefined;
  }

  /**
   * 
   */
  public getNaPersonName(): string {
    return `${this._claimPeople[0].person?.name!} ${this._claimPeople[0].person?.lastName!} ${this._claimPeople[0].person?.secondLastName!}`;
  }

  /**
   * 
   * @returns 
   */
  public getNaVehicleBrand(): string {
    return `${Util.TRANSFORM.TEXT.firstLetterUpperCase(this._claimPeople[0].vehicle?.brand?.name!)}`;
  }

  /**
   * 
   * @returns 
   */
  public getNaVehicleModel(): string {
    return `${Util.TRANSFORM.TEXT.firstLetterUpperCase(this._claimPeople[0].vehicle?.model?.name!)}`;
  }

  /**
   * 
   * @returns 
   */
  public getNaVehicleYear(): string {
    return `${this._claimPeople[0].vehicle?.year?.value}`;
  }

  /**
   * 
   * @returns 
   */
  public isNewVehicle(): boolean {
    const vehicleYear = this._claimPeople[0].vehicle?.year?.value;

    if (vehicleYear) {
      const currentYear = new Date().getFullYear();
      const difference = currentYear - vehicleYear;
      
      if (difference <= 2)
        return true;
    }

    return false;
  }

  /**
   * 
   * @returns 
   */
  public getNaVehicleColor(): string {
    return `${this._claimPeople[0].vehicleColor?.name ? this._claimPeople[0].vehicleColor?.name : ''}`;
  }

  /**
   * 
   * @returns 
   */
  public getNaVehicleVin(): string {
    return `${this._claimPeople[0].vehicle?.vin}`;
  }

  /**
   * 
   * @returns 
   */
  public getNaVehiclePlates(): string {
    return `${this._claimPeople[0].vehicle?.plate}`;
  }

	/**
	 * 
	 * @returns 
	 */
	public hasNaVehicleValue(): boolean {
		if (this._claimPeople[0].vehicle?.comercialValue === undefined || this._claimPeople[0].vehicle?.comercialValue === null)
			return false;
		return this._claimPeople[0].vehicle?.comercialValue >= 0;
	}

	/**
	 * 
	 * @returns 
	 */
	public getNaVehicleValue(): string {
		if (this._claimPeople[0].vehicle?.comercialValue !== undefined)
			return `${this._claimPeople[0].vehicle?.comercialValue >= 0 ? this._claimPeople[0].vehicle?.comercialValue : '--'}`;
		else
			return '--';
	}

  /**
   * 
   * @returns 
   */
  public hasFolId(): boolean {
    return !!this._claimPeople[0].folId;
  }

  /**
   * 
   */
  public eDUAInvolvedCreationAllowed(): boolean {
    if (!this._liability || !this._liability.id || !this._email)
      return false;
    
    const eDUALiabilities = [Util.STATUS_CODE.CLAIM_LIABILITY.INSURED, Util.STATUS_CODE.CLAIM_LIABILITY.THIRD_DRIVER, Util.STATUS_CODE.CLAIM_LIABILITY.COLIABILITY];
    
    return eDUALiabilities.includes(this._liability.id);
  }

  /**
   * 
   * @returns 
   */
  public checkLiability(): 'emit' | 'receive' | undefined {
    let liability: 'emit' | 'receive' | undefined = undefined;

    if (this._liability?.id === Util.STATUS_CODE.CLAIM_LIABILITY.INSURED)
      liability = 'emit';
    else if (this._liability?.id === Util.STATUS_CODE.CLAIM_LIABILITY.THIRD_DRIVER)
      liability = 'receive';

    return liability;
  }

  /**
   * 
   * @param value 
   */
  public setInvolvedsSectionCompleted(value: boolean) {
    this._isInvolvedsCompleted = value;
  }

  /**
   * 
   * @returns 
   */
  public checkServiceIssuedInvolveds(): boolean {
    const allowedServices = [Util.STATUS_CODE.CLAIM_SERVICE_TYPES.SIPAC, Util.STATUS_CODE.CLAIM_SERVICE_TYPES.TRADITIONAL_ORDER];
    const _externalInvolveds = this._claimPeople.filter((_, idx) => idx > 0);
    const involvedsWithFolId = _externalInvolveds.filter((i) => !!i.folId);

    return involvedsWithFolId.every((involved) => !!involved.folId && involved.services.find((s) => s.type && allowedServices.includes(s.type.id)))
  }

  /**
   * 
   * @param currentClaimRegister 
   * @returns 
   */
  public allowedSignature(currentClaimRegister: ClaimRegister): boolean {
    const allowedByClaimRegister = !currentClaimRegister.signature && currentClaimRegister.services.length > 0;
    const allowedByClaimAdjuster = this._isFirstForm;
    const claimRegisterEDUAException = (!currentClaimRegister.folId || (currentClaimRegister.personType.isNA() && !!currentClaimRegister.folId));
    
    return allowedByClaimRegister && allowedByClaimAdjuster && claimRegisterEDUAException;
  }

  /**
   * 
   */
  public isUnfinished(): boolean {
    let notices: string[] = [];
    const hasCancelledReason = this.reason !== undefined && this.reason.id.length > 0;

    /** Check if claim has services but also cancelled status. */
    const cancelledClaimWithServices = this.claimPeople.some(p => p.services.length > 0) && hasCancelledReason;
    if (cancelledClaimWithServices)
      notices.push(`El siniestro contiene servicios, a pesar de que al ser ${this.reason?.name} no se requieren.`)
    
    for (const person of this.claimPeople) {
      const isNA = person.personType.isNA();
      const isThirdPartyDriver = person.personType.isThirdPartyDriver();

      /** Check if involved doesn't have first or last name. */
      const involvedWithoutLastnames = (!person.person || !person.person.lastName || !person.person.secondLastName || person.person.lastName.length === 0 || person.person.secondLastName.length === 0);
      if (involvedWithoutLastnames)
        notices.push(`No se añadieron los apellidos del ${person.personType.name} ${person.person.name}.`);

      /** Check if involved doesn't have phone or email. */
      const involvedWithoutPhoneOrEmail = (!person.person || !person.person.email || !person.person.profile?.phone || person.person.email.length < 5 || person.person.profile.phone.number.length === 0);
      if (involvedWithoutPhoneOrEmail)
        notices.push(`No se añadió el número de teléfono o email del ${person.personType.name} ${person.person.name}.`);

      /** Check if involved doesn't have birthdate or gender. */
      const involvedWithoutBirthdateOrGender = (!person.person || !person.person.profile?.birthdate || person.person.profile.gender < 0);
      if (involvedWithoutBirthdateOrGender)
        notices.push(`No se añadió la fecha de nacimiento y/o sexo del ${person.personType.name} ${person.person.name}.`);

      /** Check if involved doesn't have driving license. */
      const involvedWithoutDrivingLicense = (isNA || isThirdPartyDriver) && person.drivingPermissionPictures.length === 0;
      if (involvedWithoutDrivingLicense)
        notices.push(`No se añadió foto de la tarjeta de circulación del ${person.personType.name} ${person.person.name}`);

      /** Check if involved doesn't have  */
      const involvedWithoutLicenseNumber = (isNA || isThirdPartyDriver) && (!person.license || person.license.number.length === 0);
      if (involvedWithoutLicenseNumber)
        notices.push(`La sección de licencia del ${person.personType.name} ${person.person.name} está incompleta.`);

      /** Check if involved doesn't have personal data. */
      const involvedWithoutVehicleData = (isNA || isThirdPartyDriver) && ((!person.vehicleType || person.vehicleType.id.length === 0) || (!person.vehicleColor || person.vehicleColor.id.length === 0)
        || person.vehicle?.brand?.name.length === 0 || person.vehicle?.model?.name?.length === 0 || person.vehicle?.year?.value! < 0 || !person.vehicle?.plate || person.vehicle?.plate.length === 0);
      if (involvedWithoutVehicleData)
        notices.push(`La sección de datos del vehículo del ${person.personType.name} ${person.person.name} está incompleta.`);

      /** Check if involved doesn't have completed vehicle pictures */
      const involvedWithoutVehiclePictures = (isNA || isThirdPartyDriver) && person.vehiclePictures.length < 4;
      if (involvedWithoutVehiclePictures)
        notices.push(`No se subieron las cuatro fotos generales del vehículo del ${person.personType.name} ${person.person.name}`);

      /** Check if involved doesn't have ine nor license. */
      const involvedWithoutIneNorLicense = (!person.inePictures || person.inePictures.length === 0) && (!person.licensePictures || person.licensePictures.length === 0);
      if (involvedWithoutIneNorLicense)
        notices.push(`No se subieron las fotos de licencia o INE del ${person.personType.name} ${person.person.name}.`);

      /** Check if involved is NA or third party driver without vin pictures */
      const involvedWithoutVin = (isNA || isThirdPartyDriver) && (person.vinPictures.length === 0 || !person.vehicle?.vin || person.vehicle?.vin.length === 0);
      if (involvedWithoutVin)
        notices.push(`No se completó la pantalla de número de serie del ${person.personType.name} ${person.person.name}.`);

      /** Check if involved has duplicated damages. */
      const hasDuplicateDamages = person.damages.some((p, index, self) => 
        self.findIndex(_p => _p.type.id === p.type.id) !== index
      );
      if (hasDuplicateDamages)
        notices.push(`El ${person.personType.name} ${person.person.name} tiene daños duplicados.`);

      /** Check if involved has duplicated services with same coverage. */
      const hasDuplicateServices = person.services.some((s, index, self) => 
        self.some((_s, _index) => {
          return !s.type?.isSipacServiceType() && !_s.type?.isSipacServiceType() && _s.type?.id === s.type?.id && _s.coverage?.id === s.coverage?.id && _index !== index;
        })
      );
      if (hasDuplicateServices)
        notices.push(`El ${person.personType.name} ${person.person.name} tiene servicios duplicados.`);

      /** Involved with repair or payment service without damages. */
      const hasRepairOrPaymentService = person.services.some(s => s.type?.isRepairServiceType() || s.type?.isClaimPaymentServiceType());
      const hasDamages = person.damages.length > 0;
      if (hasRepairOrPaymentService && !hasDamages)
        notices.push(`El ${person.personType.name} ${person.person.name} no cuenta con daños registrados.`);

      /** Involved without services. */
      const ongoingClaimWithoutServices = (!person.services || person.services.length === 0) && !hasCancelledReason;
      if (ongoingClaimWithoutServices)
        notices.push(`El ${person.personType.name} ${person.person.name} no cuenta con servicios registrados.`);

      /** Vehicle damages with no pictures. */
      const damageWithoutPictures = person.damages.filter((d) => d.pictures.length === 0 );
      if (damageWithoutPictures)
        damageWithoutPictures.forEach((d) => 
          notices.push(`El daño ${d.type.name} del ${person.personType.name} ${person.person.name} no cuenta con fotografías.`)
        )

      if (person.services)
        person.services.forEach((s) => {
          
          /** Service without coverages (excluding: Sipacs) */
          const serviceWithoutCoverages = !s.coverage?.id && !s.type?.isSipacServiceType();
          if (serviceWithoutCoverages)
            notices.push(`El servicio de ${s.type?.name} del ${person.personType.name} ${person.person.name} no cuenta con cobertura.`);

          /** Service without reserve amount (excluding: Sipacs, Crane, No cost, Investigator and Lawyer types) */
          const serviceWithoutAmount = !s.amount && !s.type?.isSipacServiceType() && !s.type?.isCraneServiceType() && 
            !s.type?.isNoCostServiceType() && !s.type?.isInvestigatorServiceType() && !s.type?.isLawyerServiceType();
          if (serviceWithoutAmount)
            notices.push(`El servicio de ${s.type?.name} del ${person.personType.name} ${person.person.name} no cuenta con reserva.`);

          /** Repair or Medical service without provider. */
          const serviceWithoutProvider = (!s.provider || !s.provider?.id) && (s.type?.isRepairServiceType() || s.type?.isMedicalServiceType());
          if (serviceWithoutProvider)
            notices.push(`El servicio de ${s.type?.name} del ${person.personType.name} ${person.person.name} no cuenta con proveedor.`);

          const involvedWithoutPolicy = (s.type?.isSipacServiceType() || s.type?.isTraditionalOrderServiceType()) && !person.thirdInsuranceClaimNumber;
          if (involvedWithoutPolicy)
            notices.push(`El ${person.personType.name} ${person.person.name} cuenta con un servicio de ${s.type?.name} pero no tiene los datos de póliza en la sección de datos personales.`);

          /** Traditional order service without pictures */
          const traditionalOrderWithoutPictures = s.type?.isTraditionalOrderServiceType() && (!s.pictures || s.pictures.length === 0);
          if (traditionalOrderWithoutPictures)
            notices.push(`El servicio de orden tradicional del ${person.personType.name} ${person.person.name} no cuenta con la fotografía de la orden.`)
        })
    }
  
    this.formNotices = notices;

    return notices.length > 0;
  }
}